<template>
    <div id="login_wrapper">
        <div id="login_container">
            <div id="title">Log In</div>
            <form onsubmit="return false">
                <div id="username_container">
                    <div id="email" class="bold">Email</div>
                    <input type="text" v-model="username"/>
                    <div class="error error_un" :class="{error_active_un:username_err}">{{ username_err_msg }}</div>
                </div>
                <div id="password_container">
                    <div id="password" class="bold">Password</div>
                    <input type="password" v-model="password"/>
                    <div class="error error_pw" :class="{error_active_pw:password_err}">{{ password_err_msg }}</div>
                </div>
                <button id="login_button" @click="login">Log in</button>
            </form>
            <div id="utilities">
                <div id="utilities_title">Not Registered?</div>
                <div id="forgot_password">
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                </div>
                <div id="sign_up">
                    <router-link to="/sign-up">Sign Up</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import { mapMutations } from 'vuex';
    import store from '@/store';
    import { RouterLink } from 'vue-router';

    export default {
    name: "Login-Component",
    data: () => ({
        username: "",
        username_err: false,
        username_err_msg: "test",
        password: "",
        password_err: false,
        password_err_msg: "test",
    }),
    methods: {
        ...mapMutations(["setSidebarHidden", "setLoggedIn", "setLoggedInEmail", "setLoggedInName"]),
        async login() {
            // console.log(this.username);
            // console.log(this.password);
            this.password_err = false;
            this.username_err = false;
            if (this.username == "") {
                this.username_err = true;
                this.username_err_msg = "You have to enter an email to login";
                return;
            }
            if (this.password == "") {
                this.password_err = true;
                this.password_err_msg = "You have to enter a password to login";
                return;
            }
            var json_to_send = "";
            json_to_send = {
                "details": {
                    "email": this.username,
                    "password": this.password
                }
            };
            //const url = "https://localbeta.tradingtools.software/api/login";
            const url = `https://${document.location.hostname}/api/login`;
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(json_to_send)
            };
            try {
                var response = await fetch(url, options);
                const json_response = await response.json();
                console.log("Showing json response below");
                console.log(json_response);
                if (response.ok) {
                    console.log("Logged in succesfully!");
                    this.setSidebarHidden(false);
                    this.setLoggedIn(true);
                    this.setLoggedInEmail(this.username);
                    //@JA - Safe at this point to call getSession 'action' to change 'store' variables via 'mutations' correctly
                    try {
                        response = await store.dispatch("getSession");
                    }
                    catch (error) {
                        console.log("Get session failed");
                        console.error(error);
                    }
                    router.push({ name: "Dashboard" }); //Skip to dashboard~
                }
                else {
                    this.setLoggedIn(false);
                    switch (json_response.code) {
                        case 15: //password incorrect
                            this.password_err = true;
                            this.password_err_msg = "Password incorrect";
                            break;
                        case 2: //email not found
                            this.username_err = true;
                            this.username_err_msg = "Username (Email) not found.";
                            break;
                        case 16: //no verified email found
                            this.username_err = true;
                            this.username_err_msg = "This email address is not verified"; //TODO: make link to verify email
                            break;
                    }
                }
            }
            catch (error) {
                this.conf_err = true;
                this.conf_err_msg = "Too many attempts.  Please try again later.";
            }
        }
    },
    components: { RouterLink }
}
</script>

<style scoped>
#title{
    font-size:24px;
    width:100%;
    text-align: center;
    font-weight: bold;
    margin-top:10px;
}
#email,#password{
    margin-top:10px;
    margin-bottom:4px;
}
.error_active_un,.error_active_pw{
    display:flex !important;
}
form{
    width:100%;
}
.error{
    color: white;
    display:none;
    width:100%;
    margin-top:5px;
    margin-bottom:5px;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    background-color: #e10000;
    border-radius: 20px;
    padding: 4px;
    white-space: nowrap;
    overflow:hidden;
}
#login_wrapper{
    display: flex;
    width: 100%;
    flex-grow:1;
    justify-content: center;
    align-items: flex-start;
    background-color: #cecee0; 
}

#login_container{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-content:center;
    background-color: #eff2f6;
    padding:10px;
    margin:10px;
    box-shadow: 0px 0px 12px #999;
}
#username_container,#password_container{
    width:100%;
    display: flex;
    flex-wrap: wrap;
}

input{
    width:100%;
    height:40px;
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    padding:0px 10px;
    font-size:16px;
    align-items: center;
    color:#333;
}
.bold{
    font-weight:bold;
}
#login_button{
    cursor:pointer;
}
#login_button:hover{
    background-color: rgb(63, 158, 253);
}
button {
    width: 100%;
    height: 40px;
    background-color:rgb(121, 188, 255);
    border: 1px solid rgb(95,167,240);
    font-weight:bold;
    color: #FFF;
    margin: 20px 0px;
    border-radius: 4px;
}
#utilities{
    /* background-color:red; */
    display:flex;
    flex-wrap: wrap;
    width: 100%;
}
#utilities_title{
    font-size:12px;
    flex-grow:1;
    font-weight:bold;
}

#sign_up{
    margin-top:10px;
    font-size:11px;
    display:flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
}

#forgot_password{
    /* background-color:yellow; */
    font-size:11px;
    display:flex;
    /* width:100%; */
    justify-content: right;
    margin-left: 20px;
    /* margin-top:10px; */
}

a{
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}
a:visited{
    color:rgb(95 167 240)
}

 /* Tablet menu */
@media all and (min-width: 700px){
    #login_container{
        padding:20px;
    }
    
    #login_container{
        display:flex;
        max-width:700px;
        justify-content: center;
    }
}

</style>