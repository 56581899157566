<template>
    <div id="profile_wrapper">
        <div id="profile_container">
            <div id="profile_main">
                <div id="profile_title_area">
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-user"/></div>
                    <div class="title">Profile</div>
                </div>
                <div id="profile_content">
                    <div id="update_profile_title">Update Profile</div>
                    <div id="profile_items">
                        <div id="first_name_title">First Name:</div>
                        <input type="text" v-model="first_name"/>
                        <div id="last_name_title">Last Name:</div>
                        <input type="text" v-model="last_name"/>
                        <div id="discord_username_title">Discord Username:</div>
                        <div id="discord_username_area">
                            <input type="text" v-model="discord_username"/><font-awesome-icon class="red" :class="{green:discord_verified}" icon="fa-brands fa-discord" />
                        </div>
                        <div></div>
                        <label for="timezone">Select a Timezone:</label>
                        <div>
                            <select id="timezone" name="timezone" v-model="timezone">
                                <option></option>
                                <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                <option value="America/Managua">(GMT-06:00) Central America</option>
                                <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                <!-- Add more options for other timezones -->
                            </select>
                        </div>
                    </div>
                    <div id="button_area">
                        <button @click="updateProfile">Submit</button>
                    </div>
                    <div class="error error_cp" :class="{error_active_submit:err}">{{ err_message }}</div>
                    <div class="success success_cp" :class="{success_active_submit:profile_update_success}">{{ profile_update_success_message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
    import { mapState } from 'vuex'
    export default {
        computed:{
            ...mapState(['licenses']),
        },
        methods:{
            updateProfile: async function(){
                var json_to_send = "";
                json_to_send = {
                    "details": {
                        "first_name": this.first_name,
                        "last_name": this.last_name,
                        "discord_username": this.discord_username,
                        "timezone": this.timezone
                    }
                };
                //const url = "https://localbeta.tradingtools.software/api/security/change-password";
                const url = `https://${document.location.hostname}/api/profile/update`;
                const options = {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify(json_to_send)
                };
                try{
                    var response = await fetch(url, options);
                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if (response.ok) {
                        console.log("Response was OK");
                        this.profile_update_success = true; //@JA - This will show a success message that is planned to disapear after a second.
                        setTimeout(() => {
                            this.profile_update_success = false;
                        },1000);
                    }else{
                        console.log("NOT OK for now");
                    }
                }catch(error){
                    console.log("Failed to connect to server /api/profile/update");
                    console.error(error);
                    this.err = true;
                    this.err_msg = "Error connecting to server";
                }
            },
            handleUpdates: function(){
                //@JA - Read session state to determine if buy disabled is true.
                //@JA - It's determined to be true IF a license is found for the user.
                console.log("Printing Licenses from license_keys.vue...");
                console.log(this.licenses);
                var filteredLicenses;
                try {
                    filteredLicenses = this.licenses.filter(function (el){
                        return (el.product_code == '1' || el.product_code == '3' || el.product_code == '4')
                    });
                    if(filteredLicenses.length > 0){
                        var filteredLicense = filteredLicenses[0];
                        this.discord_verified = filteredLicense.user.profile.discord_verified;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        },
        data: () => ({
            err: false,
            err_message:"Unknown error occured",
            first_name: "",
            last_name: "",
            discord_username: "",
            discord_verified: false,
            timezone:"",
            profile_update_success: false,
            profile_update_success_message: "Profile updated successfully"
        }),
        mounted() {
            (async() => {
                console.log("mounted lifecycle hook");
                //@JA - Read from the database and set the profile values.
                const url = `https://${document.location.hostname}/api/profile`;
                const options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };
                try{
                    var response = await fetch(url, options);
                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if (response.ok) {
                        console.log("response was OK");
                        this.first_name = json_response.details.first_name;
                        this.last_name = json_response.details.last_name;
                        this.discord_username = json_response.details.discord_username;
                        this.discord_verified = json_response.details.discord_verified;
                        this.timezone = json_response.details.timezone;
                    }else{
                        this.err = true;
                        this.err_msg = "Error with request for profile information";
                    }
                }catch(error){
                    console.log("Failed to connect to server /api/profile");
                    console.error(error);
                    this.err = true;
                    this.err_msg = "Error connecting to server";
                }
            })();  
        },
        watch: {
            licenses: {
                handler(newValue, oldValue) {
                    console.log("License vuex change detected");
                    this.handleUpdates(newValue,oldValue);
                },
                deep: true,
                immediate:true,
            },
        },
    }
    
</script>
<style scoped lang="scss">
    @keyframes fadeInOut {
        0% {opacity: 0;}
        20% {opacity:1;}
        80% {opacity:1;}
        100% {opacity: 0;}
    }
   
    .success{
        color: white;
        display:none;
        width:100%;
        margin-top:5px;
        margin-bottom:5px;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        background-color: #217e15;
        border-radius: 40px;
        padding: 6px 10px;
    }
    .error{
        color: white;
        display:none;
        width:100%;
        margin-top:5px;
        margin-bottom:5px;
        justify-content: center;
        font-weight: bold;
        font-size: 10px;
        background-color: #e10000;
        border-radius: 20px;
        padding: 4px;
        overflow:hidden;
        text-align: center;
    }
    .red{
        color:red;
    }
    .green{
        color:green;
    }
    .error_active_submit,.success_active_submit{
        display:flex !important;
    }
    .success_active_submit{
        animation: fadeInOut 1s ease-in-out 0s 1 normal forwards;
    }
    #profile_wrapper{
        display:flex;
        flex-grow: 1;
    }
    #profile_container{        
        background-color: #cecee0;
        width: 100%;
        height: 100%;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:flex-start;
        justify-content: center;
    }
    #profile_main{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #profile_title_area{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 10px 0;
        color:#393f47;
        width:100%;
        max-width: 720px;
    }
    #profile_content{
        display: flex;
        flex-direction: column;
        padding:10px;
        background-color: #eff2f6;
        box-shadow: 0 0 8px rgb(165, 165, 165);
    }
    #update_profile_title{
        font-size: 14px;
        font-weight: bold;
        color:#393f47;
        margin-bottom:20px;
        text-align: center;
    }
    #profile_items input, #profile_items select{
        width:100%;
        padding:0 4px;
        margin-bottom:10px;
        max-width:260px;
    }
    #profile_items{
        color: #393f47;
        font-size: 12px;
        font-weight: bold;
    }
    #button_area{
        display:flex;
        justify-content: center;
    }
    button{
        padding:2px;
    }
    .icon{
        font-size:24px;
        padding-right:5px;
    }
    .title{
        font-size:24px;
        font-weight: bold;
    }
    #discord_username_area{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content: center;
        svg {
            margin-bottom:10px;
            padding-left:10px;
        }
    }
    /* Tablet menu */
    @media all and (min-width: 700px){
        #profile_wrapper{
            align-items:center;
        }
        
        #profile_container{
            margin-left:180px;
        }
    }
</style>