<template>
    <transition name="modal-animation">
        <div v-show="modalActive" class="modal">
            <transition name="modal-animation-inner">
                <div v-show="modalActive" class="modal-inner">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" @click="close"/>
                    <!-- Modal Content -->
                    <div class="modal-content">
                        <slot name="content"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                    <!-- <button @click="close" type="button" class="closeButton">Close</button> -->
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    setup(props, {emit}){
        console.log("setting up modal...");
        const close = () => {
            emit('close');
        };

        return { close }
    },
    props: ["modalActive"],
}
</script>

<style lang="scss" scoped>
    .modal-animation-enter-active, .modal-animation-leave-active {
        transition: opacity 0.3s cubic-bezier(0.52,0.02,0.19,1.02);
    }
    .modal-animation-enter-from, .modal-animation-leave-to {
        opacity: 0;
    }
    
    .modal-animation-inner-enter-active{
        transition: all 0.3s cubic-bezier(0.52,0.02,0.19,1.02) 0.15s;
    }
    .modal-animation-inner-leave-active{
        transition: all 0.3s cubic-bezier(0.52,0.02,0.19,1.02);
    }

    .modal-animation-inner-enter-from{
        opacity:0;
        transform: scale(0.8);
    }
    .modal-animation-inner-leave-to{
        transform: scale(0.8);
    }

    .fa-circle-xmark {
        align-self: flex-end;
        font-size:20px;
        cursor:pointer;
        &:hover {
            color:crimson;
        }
    }
    .closeButton{
        width:100px;
    }
    .modal{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:fixed;
        height:100vh;
        width:100vw;
        margin-left: var(--nav-sidebar-width); /*These will adjust for the sidebar and fixed header*/
        margin-top: calc(-1 * var(--nav-height));
        background-color:rgba(0,0,0,0.6);
    }
    .modal-inner{
        display:flex;
        flex-direction: column;
        padding:16px 16px;
        background-color:rgba(255,255,255,1);
        box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
    }
    .modal-footer{
        display:flex;
        justify-content: space-between;
    }
    .modal-content{
        display:flex;
        flex-direction: column;
    }
    button {
        padding:10px 10px;
        border:none;
        font-size:16px;
        background-color: crimson;
        color:#FFF;
        cursor:pointer;
    }
</style>