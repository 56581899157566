<template>
    <div id="dashboard_wrapper">
        <div id="dashboard_container" :class="{hidden:sessionReady==false}">              
            <div id="dashboard_main">
                <div id="dashboard_title_area">
                    <div id="title_icon" class="icon"><i class="fa-solid fa-gauge-high"></i></div>
                    <div id="title" class="bold">Dashboard</div>
                </div>
                <div id="items_container">
                    <div id="quick_access">
                        <div class="title_bar">
                            <div class="title_bar_text bold">Quick Access</div>
                            <div class="toggle_button"><font-awesome-icon icon="fa-solid fa-chevron-up" /></div>
                        </div>
                    </div>

                    <router-link class="button_link" to="/license-keys">
                        <div id="item_license_keys">
                            <font-awesome-icon icon="fa-solid fa-key" />
                            <div class="alerts" :class="{hidden:alerts_license_keys==''}">{{ alerts_license_keys }}</div>
                            <div class="item_desc">License Keys</div>
                        </div>
                    </router-link>

                    <router-link class="button_link" to="/security">
                        <div id="item_security">
                            <font-awesome-icon icon="fa-solid fa-id-card" />
                            <div class="alerts" :class="{hidden:alerts_security==''}">{{ alerts_security }}</div>
                            <div class="item_desc">Security</div>
                        </div>
                    </router-link>
                    <!--TODO: Change the urls for billing and buy now when inactive(right now it's linking to billing when it should not be, it should go to)-->
                    <a class="button_link" :href="billingurl" :class="{a_disabled:billing_disabled==true}">
                        <div id="item_billing" :class="{disabled:billing_disabled==true}">
                            <font-awesome-icon icon="fa-solid fa-file-invoice-dollar" />
                            <div class="alerts" :class="{hidden:alerts_billing==''}">{{ alerts_billing }}</div>
                            <div class="item_desc">Billing & Invoices</div>
                        </div>
                    </a>

                    <a class="button_link" href="#" @click.prevent="showSubscriptionModal()" :class="{hidden:new_buyer==false}">
                        <div id="new_buyer">
                            <font-awesome-icon icon="fa-solid fa-dollar-sign" />
                            <div class="alerts" :class="{hidden:alerts_buy==''}">{{ alerts_buy }}</div>
                            <div class="item_desc"><div v-html="buy_inner_html"></div></div>
                        </div>
                    </a>

                    <a class="button_link" href="#" @click.prevent="showSubscriptionModal()" :class="{hidden:new_buyer==true,click_disabled:disable_click==true}">
                        <div id="existing_buyer" :class="{active:license_state=='active',expired:license_state=='expired',deactivated:license_state=='deactivated'}">
                            <div class="alerts" :class="{hidden:alerts_buy==''}">{{ alerts_buy }}</div>
                            <div class="item_desc"><div v-html="buy_inner_html"></div></div>
                        </div>
                    </a>
            
                    <!-- <router-link to="/videos">
                        <div id="item_videos">
                            <font-awesome-icon icon="fa-solid fa-video" />
                            <div class="alerts" :class="{hidden:alerts_videos==''}">{{ alerts_videos }}</div>
                            <div class="item_desc">Videos</div>
                        </div>
                    </router-link> -->

                    <!-- <div id="item_account">
                        <div class="alerts">{{ alerts_account }}</div>
                        <div class="account icon">
                            <router-link to="/account"><font-awesome-icon icon="fa-solid fa-user" /></router-link>
                        </div>
                        <div class="item_desc"><router-link to="/account">Account</router-link></div>
                    </div> -->
                    <!-- <div id="item_products">
                        <div class="alerts">{{ alerts_products }}</div>
                        <div class="products icon">
                            <router-link to="/products"><font-awesome-icon icon="fa-solid fa-box-open" /></router-link>
                        </div>
                        <div class="item_desc"><router-link to="/products">Products</router-link></div>
                    </div> -->

                    <!-- <div id="item_buy">
                        <div class="alerts" :class="{hidden:alerts_buy==''}">{{ alerts_buy }}</div>
                        <div class="buy icon">
                            <a :href="buyurl" target="_blank"><font-awesome-icon icon="fa-solid fa-dollar-sign" /></a>
                        </div>
                        <div class="item_desc"><a :href="buyurl" target="_blank">Buy Now!</a></div>
                    </div> -->
                    <!-- <div id="item_discord">
                        <div class="alerts" :class="{hidden:alerts_discord==''}">{{ alerts_discord }}</div>
                        <div class="discord icon_brands">
                            <a href="https://discord.gg/pGHHRczpbu"><font-awesome-icon icon="fa-brands fa-discord" /></a>
                        </div>
                        <div class="item_desc"><a href="https://discord.gg/pGHHRczpbu">Discord</a></div>
                    </div> -->
                    <!-- <div id="item_youtube">
                        <div class="alerts">{{ alerts_youtube }}</div>
                        <div class="youtube icon_brands">
                            <a href="https://youtube.com"><font-awesome-icon icon="fa-brands fa-youtube" /></a>
                        </div>
                        <div class="item_desc"><a href="https://youtube.com">Youtube</a></div>
                    </div> -->
                    <div id="alternate_payment_methods_area">
                        <div id="alternate_payment_methods_text">
                            Having trouble paying with Stripe? You can pay us via <span class="paypal_link" @click="unhidePaypal">Paypal</span> or Crypto. However, please note that these payment methods are <span class="bold">not automatic and will not auto-renew</span>. In addition, if you pay via these methods, <span class="bold">we may need up to 48 hours to deliver your license key</span>.<br/><br/>If you need further assistance, please visit our <a href="https://discord.gg/pGHHRczpbu" class="custom_link">Discord Server</a> or you can email us at <a href="mailto:support@tradingtools.software" class="custom_link">support@tradingtools.software</a>
                            <div id="alternate_payment_methods_links">
                                <div id="paypal_notice"><span class="bold">Note: This PayPal payment will be for 1 month of subscription time, and the charge will be for $60.00. This payment will not auto renew.</span></div>
                                <div id="paypal-button-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal @close="toggleModal" :modalActive="modalActive">
            <template #content>
                <h1>Would you like to purchase a monthly* or yearly subscription (save 100 USD)?</h1>
            </template>
            <template #footer>
                <div>
                    <button @click="toggleModal" type="button" class="cancelButton">Cancel</button>
                    <p>* Minimum subscription period is 6 months</p>
                </div>
                <div>
                    <button @click="buyMonthly" type="button" class="monthlyButton">Monthly</button>
                    <button @click="buyYearly" type="button" class="yearlyButton">Yearly</button>
                </div>
            </template>
        </Modal>
    </div>
</template>
    
<script>
    import { mapState } from 'vuex'
    import moment from 'moment'
    import Modal from '@/components/modal.vue'
    import {ref} from 'vue'

    function initPayPalButton() {
        // eslint-disable-next-line
        paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'buynow',

            },

            createOrder: function (data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        "description": "TradingView Input Optimizer (1 Month Subscription Only)",
                        "amount": {
                            "currency_code": "USD",
                            "value": 60.00
                        }
                    }]
                });
            },

            onApprove: function (data, actions) {
                return actions.order.capture().then(function (orderData) {

                    // Full available details
                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                    // Show a success message within this page, e.g.
                    const element = document.getElementById('paypal-button-container');
                    element.innerHTML = '';
                    element.innerHTML = '<h3>Thank you for your payment!</h3>';

                    // Or go to another URL:  actions.redirect('thank_you.html');

                });
            },

            onError: function (err) {
                console.log(err);
            }
        }).render('#paypal-button-container');
    }
    
    export default {
        name : "Dashboard",
        components: {
            Modal,
        },
        setup(){
            console.log('dashboard setup');
            const modalActive = ref(false);

            const toggleModal = () => {
                modalActive.value = !modalActive.value;
            }

            return {modalActive, toggleModal};
        },
        methods: {
            handleUpdates: function(){
                //@JA - Read session state to determine if buy disabled is true.
                //@JA - It's determined to be true IF a license is found for the user.
                console.log("Printing Licenses from dashboard.vue...");
                console.log(this.licenses);
                var filteredLicenses = this.licenses.filter(function (el){
                    return (el.product_code == '1' || el.product_code=='3' || el.product_code=='4')
                });
                
                var filteredLicense = filteredLicenses[0];
                this.license_info = filteredLicense;
                console.log('Filtered License Below:');
                console.log(filteredLicense);
                if(typeof filteredLicense != "undefined"){
                    this.buy_or_bill_url = (this.license_info.subscriptions[0].stripe_subscriptionID==null || this.license_info.subscriptions[0].stripe_subscriptionID=='' || typeof this.license_info.subscriptions[0].stripe_subscriptionID=='undefined') ? 'buy' : 'billing';
                    if(filteredLicense.active == true){
                        let license_expiration_date = moment.unix(this.license_info.subscriptions[0].current_period_end_timestamp);
                        let current_date = moment();
                        this.new_buyer = false;
                        this.billing_disabled = false;
                        if(current_date.isAfter(license_expiration_date)){
                            this.license_state = 'expired';
                        }else{
                            this.license_state = 'active';
                        }
                        
                    }else{
                        this.new_buyer = false;
                        this.billing_disabled = false;
                        this.license_state = 'deactivated';
                    }
                    //if no stripe customer id detected, then the billing_disabled should = true
                    if(this.license_info.user.stripe_customerID != "true"){
                        this.billing_disabled = true;
                        if(this.license_state == 'active'){
                            this.disable_click = true; //@JA - This was created through the CLS API to manually create a license in this situation.
                        }
                    }
                }else{
                    //Condition FALSE
                    console.log("No license found, making billing disabled and setting new buyer to true");
                    this.new_buyer = true;
                    this.billing_disabled = true;
                    this.buy_or_bill_url = 'buy';
                }   
            },
            unhidePaypal: function(){
                document.getElementById("paypal_notice").style.display = "block";
                document.getElementById("paypal-button-container").style.display = "block";
            },
            showSubscriptionModal: function(){
                this.toggleModal(); //@JA - This will make the modal visible
            },
            buyMonthly : function(){
                const currentBaseUrl = window.location.origin;
                window.location.href = `${currentBaseUrl}/optimizer/stripe/buy-license?price=monthly`;
            },
            buyYearly: function(){
                const currentBaseUrl = window.location.origin;
                window.location.href = `${currentBaseUrl}/optimizer/stripe/buy-license?price=yearly`;
            }
        },
        watch: {
            deep: true,
            licenses: {
                handler(newValue, oldValue) {
                    console.log("License vuex change detected");
                    this.handleUpdates(newValue,oldValue);
                },
            },
        },
        computed: {
            ...mapState(['loggedInEmail','accountID','licenses','sessionReady']),
            billingurl: function(){
                // return process.env.VUE_APP_BILLING_URL+'?prefilled_email='+this.loggedInEmail;
                return "/stripe/billing";
            },
            tvio_buyurl: function(){
                //return process.env.VUE_APP_BUY_URL+'?prefilled_email='+this.loggedInEmail+'&client_reference_id='+this.accountID
                return "/optimizer/stripe/buy-license";
            },
            buy_inner_html() {
                if(this.new_buyer==false){
                    if(this.license_state=="active"){
                        return `
                            <div class="license_status">Active</div>
                            <div class="hl"></div>
                            <div class="license_active">Expires: ${moment.unix(this.license_info.subscriptions[0].current_period_end_timestamp).format("MMMM Do, YYYY")}</div>`;
                    }
                    if(this.license_state=="expired"){
                        return `
                            <div class="license_status">Expired</div>
                            <div class="license_expired">${moment.unix(this.license_info.subscriptions[0].current_period_end_timestamp).format("MMMM Do, YYYY")}</div>`;
                    }
                    if(this.license_state=="deactivated"){
                        return `
                            <div class="license_status">Inactive</div>
                            <div class="hl"></div>
                            <div class="license_deactivated">Please Renew Your Subscription</div>`
                    }
                    return `<div class="error_text">Error</div>`;
                }else{
                    return `<div>Buy Now!</div>`;
                }
            },
        },
        data: () => ({
            alerts_license_keys:'',
            alerts_security:'',
            alerts_account:'',
            alerts_products:'',
            alerts_videos:'',
            alerts_discord:'',
            alerts_youtube:'',
            alerts_billing:'',
            alerts_buy:'',
            billing_disabled:false,
            new_buyer:false,
            license_state:'expired',
            buy_or_bill_url:'buy',
            disable_click:false,
            license_info:{subscriptions:[{"current_end_period_timestamp":"123456789"}]}
        }),
        mounted(){
            this.handleUpdates();
            initPayPalButton();
        }
    }
</script>
<style lang="scss" scoped>
.modal-content{
    display:flex;
    flex-direction:column;

    h1,p {
        margin-bottom:12px;
    }

    h1 {
        font-size:32px;
    }
}
.modal-footer{
    button{
        padding:10px 15px;
        // border:1px #0080ff solid;
        border:none;
        font-size:16px;
        color:#FFF;
        cursor:pointer;
        font-weight: bold;
        text-shadow: 1px 1px 2px #555;
        text-decoration: none;
        background: transparent;
        &:hover{
            background-color: #0080ff;
        }
    }
    p {
        padding-top:5px;
        font-size: 10px;
        font-style:italic;
    }
    .cancelButton{
        background-color: crimson;
    }
    .monthlyButton{
        background-color: rgb(96, 95, 95);
    }
    .yearlyButton{
        background-color: rgb(96, 95, 95);
        margin-left:10px;
    }
}
.button_link{
    margin: 10px;
}
.error_text{
    color:red;
}
:deep(.license_status){
    font-size:14px;
}
:deep(.license_active){
    font-size:10px;
    padding-top:4px;
}
:deep(.license_expired){
    font-size:10px;
    padding-top:4px;
}
:deep(.license_deactivated){
    font-size:8px;
    padding-top:4px;
}
.custom_link{
    display:inline !important;
    text-decoration: underline;
    color:#006ab6 !important;
    cursor: pointer;
}
.paypal_link{
    text-decoration: underline;
    color:#006ab6;
    cursor: pointer;
}
#paypal-button-container,#paypal_notice{
    display: none;
    /* background-color: red; */
    padding: 10px;
}
#paypal-button-container .paypal-buttons{
    min-height: 280px !important;
}
#alternate_payment_methods_area{
    text-align:center;
    width:100%;
    border-top: 1px solid #cecee0;
}
#alternate_payment_methods_text{
    padding:10px;
    font-size: 14px;
    line-height: 16px;
}
#title{
    font-size:24px;
}
:deep(.hl){
    height:1px;
    width:calc(100% + 20px);
    margin-left:-10px;
    margin-top:4px;
    margin-bottom:0px;
    background-color: #eff2f6;
}
.a_disabled{
    cursor:default !important;
    pointer-events:none;
}
.disabled{
    background: rgb(210, 210, 210) !important;
    cursor:default;
    animation: none !important;
}
.click_disabled{
    pointer-events:none;
}
.disabled svg{
    color:rgb(139, 139, 139) !important;
    animation: none !important;
    cursor:default;
}
.disabled svg:hover,.disabled svg:not(:hover){
    animation: none !important;
}
.disabled .item_desc{
    color:rgb(139, 139, 139) !important;
    animation: none !important;
}
.disabled .alerts{
    animation: none !important;
}
#items_container a{
    display: flex;
    color: #393f47;
    justify-content: space-between;
    backface-visibility: hidden;
    
}
/* #items_container a:hover :not(svg){
    animation-name: zoom;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
}
#items_container a :not(:hover){
    animation-name:zoom;
    animation-duration:0.5s;
    animation-direction:reverse;
    animation-fill-mode: forwards;
}

@keyframes zoom{
    0%{scale:100%}
    100%{scale:105%}
} */
#items_container svg{
    color:#393f47;
    width:24px;
    height:24px; 
}
.hidden{
    display:none !important;
}
#quick_access{
    width:100%;
}
.toggle_button{
    width:32px;
    height:32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #ccc;
    display:none;
    
}
.toggle_button {
    font-weight:bold;
    font-family: "Font Awesome 6 Free";
    color:#393f47
}
.title_bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:32px;
    width:100%;
    border-bottom: 1px solid #ccc;
    background-color: #eff2f6;
}
.title_bar_text{
    margin-left:10px;
    color:#393f47;
    font-size: 13px;
}
#dashboard_wrapper{
    display:flex;
    flex-grow:1;
    background-color: #cecee0;
}
#dashboard_menu,#dashboard_info{
    display: flex;
}
#dashboard_info{
    color: #393f47;
}
#dashboard_title_area{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 10px 0 0 0;
    color:#393f47;
    width:100%;
    max-width:720px;
}
#items_container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #eff2f6;
    box-shadow: 0px 0px 12px #999;
    margin:10px;
    max-width: 460px;
}
.item_desc{
    width:100%;
    border-radius:16px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    /* color: #0b71d9; */
    /* color:#393f47; */
    text-align: center;
}
.icon{
    display:flex;
    justify-content: center;
    align-items: center;
    width:32px;
    height:32px;
    margin-right:5px;
    font-family: "Font Awesome 6 Free";
    font-size: 24px;
    line-height: 16px;
    font-weight: 900;
    border-radius: 50px;
    -webkit-text-stroke: 1px #ccc;
    text-shadow: 0px 0px 4px #ccc;
}
.icon_brands{
    display:flex;
    justify-content: center;
    align-items: center;
    width:32px;
    height:32px;
    margin-right:5px;
    /* background-color: rgb(81, 168, 255);  */
    font-family: "Font Awesome 6 Brands";
    font-size: 24px;
    line-height: 16px;
    font-weight: 900;
    border-radius: 50px;
    color: #fff;
    /* box-shadow: 0px 0px 16px rgb(81, 168, 255); */
    -webkit-text-stroke: 1px #ccc;
    text-shadow: 0px 0px 4px #ccc;
}
#item_license_keys,#new_buyer,#existing_buyer,#item_security,#item_account,#item_products,#item_videos,#item_discord,#item_youtube,#item_billing{
    width:130px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 6px #aaa;
    border-radius: 12px;
    padding: 10px;
    height:50px;
}
#item_license_keys{
    background: linear-gradient(0deg, dodgerblue 10%, #eff2f6 10%);
}
#item_security{
    background: linear-gradient(0deg, goldenrod 10%, #eff2f6 10%);
}
#item_billing{
    background: linear-gradient(0deg, rgb(0, 196, 190) 10%, #eff2f6 10%);
}
#new_buyer{
    background: linear-gradient(0deg, rgb(10, 105, 1) 0%, #02bd0e 100%);
    color:white !important;
}
.deactivated{
    background: linear-gradient(0deg, rgb(255, 0, 0) 0%, #be0000 100%);
    color:white !important;
}
.active{
    background: linear-gradient(0deg, rgb(0, 148, 228) 0%, #006ab6 100%);
    color:white !important;
}
.expired{
    background: linear-gradient(0deg, rgb(255, 191, 0) 0%, #ffdd00 100%);
    color:black !important;
}

#new_buyer svg{
    color:white;
}
/* #item_account{
    background: linear-gradient(0deg, darkred 10%, #eff2f6 10%);
} */
/* #item_products{
    background: linear-gradient(0deg, rebeccapurple 10%, #eff2f6 10%);
} */
/* #item_videos{
    background: linear-gradient(0deg, darkgreen 10%, #eff2f6 10%);
} */
/* #item_discord{
    background: linear-gradient(0deg, #7289da 10%, #eff2f6 10%);
} */
/* #item_youtube{
    background: linear-gradient(0deg, red 10%, #eff2f6 10%);
} */

.buy a{color:white !important;}

.alerts{
    background-color: red;
    color:#fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -6px;
    right: -6px;
    border-radius: 24px;
    padding:2px 4px;
    font-size: 12px;
    min-width: 24px;
    min-height: 24px;
    height:24px;
    font-family: 'Ubuntu','Arial','sans-serif';
    font-weight: bold;
    box-shadow: 1px 1px 4px #888;
}


#dashboard_container{
    /* padding-top:62px; */
    margin-bottom:30px;
    width: 100%;
    height: 100%;
    /* background-color:red; */
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* align-content:space-around; */
    justify-content: center;
    /* margin: 20px; */
    /* background-color: #96c2ed; */
    /* background-color: #69a8e7; */
    /* box-shadow: 0px 0px 10px #96c2ed; */
    /* color:#eee; */
    /* text-shadow: 1px 1px 2px #777; */
}

#dashboard_container a::hover{
    text-decoration: underline;
}
/* fineprint{
    text-shadow: 1px 1px 1px #666;
} */
#dashboard_wrapper{
    /* background-color: dodgerblue; */
    display: flex;
    flex-direction: row;
    width: 100%;
    /* min-height:100%; */
    align-items: flex-start;
    justify-content: center;
}

input{
    width:100%;
    height:40px;
    /* margin: 10px 20px; */
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    padding-left:10px;
    font-size:16px;
    align-items: center;
    color:#333;
}
.bold{
    font-weight:bold;
}

h1 {
    /* background-color:green; */
    font-size:150%;
    /* width:100%; */
    height:40px;
    line-height: 40px;
    /* padding:20px; */
}
h2 {
    /* background-color:blue; */
    width:100%;
    height:40px;
    line-height: 40px;
    /* padding:20px; */
}

a {
    /* color: #0b71d9; */
    /* color: #393f47; */
    text-decoration: none;
    
}
a:visited{
    /* color: #0b71d9; */
    color:#393f47;
    /* color:rgb(95 167 240) */
}
/*Mobile Adjustment Menu */
@media all and (max-width:469px){
    #items_container{
        max-width: 300px;
    }
}
/* Tablet menu */
@media all and (min-width: 700px){

    #dashboard_main{
        position: relative;
    }
    
    /* #dashboard_title_area{
        text-align: left;
    } */
    #dashboard_wrapper{
        align-items:flex-start;
        padding-left:0;
    }
    #dashboard_menu{
        position:fixed;
        top:62px;
        left:0;
        width:220px;
        min-height:calc(100% - 92px);
        background-color: darkblue;
    }
    #dashboard_container{
        margin-left: var(--nav-sidebar-width);
    }
    #sign_up_names{
        justify-content: space-between;
    }
    #firstname_container{
        width:48%;
    }
    #lastname_container{
        width:48%;
    }
    
    #sign_up_container{
        display:flex;
        width:700px;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:space-around;
    }
    #sign_up_container h3{
        width:100%;
    }
    #sign_up a::after{
    content:"Tap Here To Login"
    }
    #items_container{
        max-width: 460px;
    }
}
/* Desktop menu */
@media all and (min-width: 960px){
    #items_container{
        max-width: 610px;
        justify-content: flex-start;
    }
    #dashboard_title_area{
    display: flex;
    margin: 10px 0 0 10px;
    align-items: center;
    text-align: left;
    justify-content: left;
}
    
}
</style>