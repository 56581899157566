<template>
    <ResetPassword :reset_password_hash="reset_password_hash"></ResetPassword>
    <router-view />
</template>

<script>
    import ResetPassword from '@/components/resetpassword.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-reset-password',
        components: {
            ResetPassword
        },
        props: {
            reset_password_hash:{
                type: String,
                required: true
            }
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>