import { createApp } from 'vue'
import App from './App.vue'

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
//import { BootstrapVue } from 'bootstrap-vue';

import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHouseUser, faKey, faUser, faIdCard,faBoxOpen,faGaugeHigh,faChevronUp,faChevronDown,faVideoCamera,faFileInvoiceDollar,faDollarSign,faCopy,faEye,faEyeSlash,faTrashCan, faFileArrowDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faDiscord,faYoutube,faStripe } from '@fortawesome/free-brands-svg-icons'

library.add(faHouseUser);
library.add(faGaugeHigh);
library.add(faKey);
library.add(faIdCard)
library.add(faUser);
library.add(faBoxOpen);
library.add(faDiscord);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faVideoCamera);
library.add(faYoutube);
library.add(faStripe);
library.add(faFileInvoiceDollar);
library.add(faDollarSign);
library.add(faCopy);
library.add(faEye);
library.add(faEyeSlash);
library.add(faTrashCan);
library.add(faFileArrowDown);
library.add(faCircleXmark);

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(store).mount('#app');
