<template>
    <header>
        <nav>
            <ul class="menu">
                <li class="logo_text"><router-link to="/"><div class="logo"></div>TradingTools.Software</router-link></li>
                <!-- <div class="header_items"> -->
                  <li class="item discord" :class="{hidden:!sidebarHidden}"><a href="https://discord.gg/pGHHRczpbu"><font-awesome-icon icon="fa-brands fa-discord" />Discord</a></li>
                  <li class="item has-submenu" :class="{hidden:!sidebarHidden}"><a tabindex="0" href="#" class="submenu_toggle">Products <font-awesome-icon icon="fa-solid fa-chevron-down" /></a>
                      <ul class="submenu">
                          <li class="subitem"><a href="/#tvio_menu_anchor">TradingView Input Optimizer {{ version }}</a></li>
                          <!--<li class="subitem"><a href="#">AutoTrading Bot</a></li>-->
                      </ul>
                  </li>
                  <li class="item" :class="{hidden:!sidebarHidden}"><a href="/about-us">About Us</a></li>
                  <!--<li class="item"><router-link to="/about-us">About Us Router</router-link></li>-->
                  <li class="item button" :class="{hidden:!sidebarHidden}"><router-link to="/log-in">Login</router-link></li>
                  <li class="item button secondary" :class="{hidden:!sidebarHidden}"><router-link id="sign_up" to="/sign-up">Sign Up</router-link></li>
                  <!-- If in dashboard...-->
                  <li class="item button secondary signout" :class="{hidden:sidebarHidden}" @click="signout"><a id="sign_out" href="#">Sign Out</a></li>
                  <li class="item information" :class="{hidden:sidebarHidden}">Welcome, <span class="username">{{loggedInFirstName}}</span><div class="account_email">{{loggedInEmail}}</div><div class="accountid">ACCT #: {{accountID}}</div></li>
                  <!-- </div> -->
                <li class="toggle"><a href="#a" class="menu_toggle"><i class="fas fa-bars"></i></a></li>
            </ul>
        </nav>
    </header>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import router from '@/router';
    export default {
      computed: {
        ...mapState(['sidebarHidden','loggedInEmail','accountID','loggedInFirstName'])
      },
      methods: {
          ...mapMutations(['setSidebarHidden','setLoggedIn']),
          async signout() {
            const url = `https://${document.location.hostname}/api/signout`;

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include'
            };
              try{
                  var response = await fetch(url,options);
                  
                  const json_response = await response.json();
                  console.log("Showing json response below");
                  console.log(json_response);
                  if(response.ok){
                      console.log("Signed out succesfully!");
                      this.setSidebarHidden(true);
                      this.setLoggedIn(false);
                      router.push({name:'Home'}); //Skip to dashboard~
                  }else{
                      this.setLoggedIn(false);
                      console.log("Signout failed for unknown reasons from API");
                  }
              }catch(error){
                  console.log("Error occured signing out");
                  this.setLoggedIn(false);//assume they wanted to logout, and do so visually.
              }
              router.push({name:'Home'}); //Regardless it should always push to home.
          }
      },
      name: 'Header-Main',
      props: {
          version: String
      },
      mounted(){
          const toggle = document.querySelector(".toggle");
          const menu = document.querySelector(".menu");
          const items = document.querySelectorAll(".item");

          /* Toggle mobile menu */
          function toggleMenu() {
              if (menu.classList.contains("active")) {
                  menu.classList.remove("active");
                  toggle.querySelector("a").innerHTML = "<i class='fas fa-bars'></i>";
              } else {
                  menu.classList.add("active");
                  toggle.querySelector("a").innerHTML = "<i class='fas fa-times'></i>";
              }
          }
          function closeMenu(e){
              if(menu.classList.contains("active") && !e.target.classList.contains("fa-bars") && !e.target.classList.contains("menu_toggle") && !e.target.classList.contains("submenu_toggle")) {
                  /*let isClickInside = menu
                  .querySelector(".submenu-active")
                  .contains(e.target);
                  if(!isClickInside && menu.querySelector(".submenu-active")){*/
                      menu.classList.remove("active");
                      toggle.querySelector("a").innerHTML = "<i class='fas fa-bars'></i>"; 
                  //}
              }
          }

          /* Activate Submenu */
          function toggleItem() {
              if (this.classList.contains("submenu-active")) {
                  this.classList.remove("submenu-active");
              } else if (menu.querySelector(".submenu-active")) {
                  menu.querySelector(".submenu-active").classList.remove("submenu-active");
                  this.classList.add("submenu-active");
              } else {
                  this.classList.add("submenu-active");
              }
          }

          /* Close Submenu From Anywhere */
          function closeSubmenu(e) {
              if (menu.querySelector(".submenu-active")) {
                  let isClickInside = menu
                  .querySelector(".submenu-active")
                  .contains(e.target);

                  if (!isClickInside && menu.querySelector(".submenu-active")) {
                  menu.querySelector(".submenu-active").classList.remove("submenu-active");
                  }
              }
          }
          /* Event Listeners */
          toggle.addEventListener("click", toggleMenu, false);
          for (let item of items) {
              if (item.querySelector(".submenu")) {
                  item.addEventListener("click", toggleItem, false);
              }
              item.addEventListener("keypress", toggleItem, false);
          }
          document.addEventListener("click", closeSubmenu, false);
          document.addEventListener("click", closeMenu, false);
          
      },
      watch: {
        loggedInEmail(newVal) {
          console.log('loggedInEmail updated:', newVal);
          //@JA - This will ensure that the async grabbing of the email is complete.
          
          // if(typeof this.loggedInEmail != "undefined"){
          //   console.log(`*trackdesk calling for:${this.loggedInEmail}, loggedInFirstName=${this.loggedInFirstName}`);
          //   if(this.loggedInEmail != "?"){
          //     window.trackdesk('tvio', 'externalCid', {
          //       externalCid: `${this.loggedInEmail}`,
          //       revenueOriginId: 'c1c690a4-24ae-4658-9c0e-08be4b97e6b8'
          //     });
          //   }else{
          //     console.log("? email defined so not calling trackdesk");
          //   }
          // }
          
        }
      }
    }
</script>
<style scoped>
    #sign_up:hover,#sign_out:hover{
      background-color: #0080ff;
    }
    .menu{
      min-height: var(--nav-height);
    }
    .hidden{
      display:none !important;
    }
    .username{
      font-weight: bold;
    }
    .accountid{
      font-size:10px;
    }
    .account_email{
      font-size:11px;
    }
    .signout{
      order:3;
    }
    .information{
      order:1;
      color:white;
      font-size:14px;
    }
   .logo {
    width: 32px;
    height: 32px;
    display: inline-block;
    background-image: url('../assets/icon-32.png');
    background-repeat: no-repeat;
    background-size: 32px;
    /* vertical-align: -50%; */
    margin-right: 10px;
    color: #fff;
    fill:red;
   }
   .logo svg{
    color: #fff;
   }
   .header_items{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    order:3;
   }
  
   nav {
    /* height: var(--nav-height); */
    position: fixed;
    width: 100%;
    background: #393f47;
    padding: 0 10px 0 15px;
    z-index:100;
   }
   a {
    color: white;
    font-weight:bold;
    text-shadow: 1px 1px 2px #555;

    text-decoration: none;
   }
   .menu, .submenu {
    list-style-type: none;
   }
   .logo_text{
    order:1;
    flex-grow:1;
    font-size: 20px;
    font-family: Ubuntu,Helvetica,sans-serif;
   }
   

   .item{
    padding: 10px;
   }
   .item.button{
    padding: 9px 5px;
   }
   .item:not(.button) a:hover, .item a:hover::after, .item a:hover::before{
    color: #ccf;
   }
   /* Mobile Menu */
   .menu {
    line-height: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
   }
   
   .menu li a{
    display: block;
    padding: 15px 0px;
   }

   .menu .logo_text a{
    display:flex;
    align-items:center;
   } 

   .menu li.subitem a{
    padding: 15px;
   }
   .toggle{
    /* display:flex; */
    /* align-self:stretch; */
    order: 1;
    font-size: 20px;
    /* justify-content: center; */
   }
   .item.button{
    order: 2;
   }
   .item {
    order: 3;
    width: 100%;
    text-align: center;
    display: none;
   }
   .active .item{
    display: block;
   }
   .button.secondary{
    /* divider between buttons and menu links */
    border-bottom: 1px #444 solid;
   }
   /* Submenu up from mobile screens */
   .submenu{
    display: none;
   }
   .submenu-active .submenu{
    display: block;
   }
   .has-submenu i{
    font-size: 12px;
   }
   .discord svg{
    height:16px;
    width:16px;
    padding-right:2px;
   }
   /* .discord > a::before{
    font-family: "Font Awesome 6 Brands";
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    content: "\f392";
    color: white;
    padding-right: 5px;
   } */
   .has-submenu > a {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: white;
    padding-left: 5px;
   }
   .subitem a {
    padding: 10px 15px;
   }
   .submenu-active {
    background-color: rgb(45,51,56);
    border-radius: 3px;
   }

   /* Tablet menu */
@media all and (min-width: 700px){
  .menu {
    justify-content: center;
  }
  .logo_text {
    flex: 1;
    font-size: 20px;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }

  .toggle {
    text-align: right;
    order: 2;
    padding-left: 10px;
    width:30px;
    display:flex;
    justify-content:center;
  }

  .header_items{
    flex-grow:1;
    justify-content:right;
    padding-right:10px;
  }
  .menu li a{
    display: block;
    padding: 15px 0px;
   }
  /* Button up from tablet screen */
  .menu li.button a {
    padding: 10px 15px;
  }
  .button a {
    background: #0080ff;
    border: 1px royalblue solid;
  }
  .button.secondary {
    border: 0;
  }
  .button.secondary a {
    background: transparent;
    border: 1px #0080ff solid;
  }
  .button a:hover {
    text-decoration: none;
  }
  .button:not(.secondary) a:hover {
    background: royalblue;
    border-color: darkblue;
  }
}
/* Desktop menu */
@media all and (min-width: 960px) {
  .menu {
    flex-wrap: nowrap;
    background: none;
  }
  .information{
    text-align:right !important;
  }
  .signout{
    order:2 !important
  }
  .logo_text {
    order: 0;
    font-size: 20px;
  }
  .item {
    order: 1;
    position: relative;
    display: block;
    width: auto;
  }
  .button {
    order: 2;
  }
  .header_items{
    flex-grow:0;
    padding-right:0;
  }
  .submenu-active .submenu {
    display: block;
    position: absolute;
    left: 0;
    top: 62px;
    background: #111;
  }
  .toggle {
    display: none;
  }
  .submenu-active {
    border-radius: 0;
  }
  .submenu-active li{
    width: 250px;
  }
}
</style>