<template>
    <div id="pp_wrapper">
        <div id="pp_container">
            <div id="pp">
                <h1>Privacy Policy TradingTools Software LLC</h1>
                <div class="hr"></div>
                <div class="main-pp-content">
                    <p>Last updated:  <span class="italics">03/22/2024</span>.</p>
                    <p>This privacy notice for <span class="purple">TradingTools.Software LLC</span> (doing business as <span class="purple">TradingTools</span>) (“<span class="bold">Company</span>,” “<span class="bold">we</span>,” “<span class="bold">us</span>,” or “<span class="bold">our</span>“), describes how and why we might collect, store, use, and/or share (“<span class="bold">process</span>“) your information when you use our services (“<span class="bold">Services</span>“), such as when you:</p>
                    <ul>
                        <li>Visit our website at <a href="https://tradingtools.software">https://tradingtools.software</a>, or any website of ours that links to this privacy notice</li>
                        <li>Engage with us in other related ways ― including any sales, marketing, or events</li>
                    </ul>
                    <h3><span class="bold">Questions or Concerns?</span></h3>
                    <p>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a></p>
                    <h2 class="p-top p-bot">SUMMARY OF KEY POINTS</h2>
                    <p class="bold italics">This summary provides key points from our privacy notice, but you can find out more details about any of these topics by using our table of contents below to find the section you are looking for</p>
                    <h3><span class="bold">What personal information do we process?</span></h3>
                    <p>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with <span class="purple">TradingTools.Software LLC</span> and the Services, the choices you make, and the products and features you use.</p>
                    <h3><span class="bold">Do we process any sensitive personal information?</span></h3>
                    <p><span class="purple">We do not process sensitive personal information as defined by Article 4(13), (14) and (15) andArticle 9 and Recitals (51) to (56) of the GDPR.</span></p>
                    <h3><span class="bold">Do you receive any information from third parties?</span></h3>
                    <p>We may receive information from public databases, marketing partners, social media platforms, and other outside sources.</p>
                    <h3><span class="bold">How do you process my information?</span></h3>
                    <p>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so and strictly follow the rule to data collection minimization.</p>
                    <h3><span class="bold">In what situations and with which types of parties do we share personal information?</span></h3>
                    <p>We may share information in specific situations and with specific categories of third parties, like for example our newsletter service of which you can opt out at any time.</p>
                    <h3><span class="bold">Do you receive any information from your users with the usage of the tools they purchase?</span></h3>
                    <p>The only information we collect from our users is whether they have an active/paid subscription to use our services. No other information was, is or will ever be collected without the written prior explicit consent from our users.</p>
                    <h3><span class="bold">How do we keep your information safe?</span></h3>
                    <p>We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
                    <h3><span class="bold">What are your rights?</span></h3>
                    <p>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
                    <h3><span class="bold">How do I exercise my rights?</span></h3>
                    <p>The easiest way to exercise your rights is by sending us an e-mail to <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                    <p>Want to learn more about what <span class="purple">TradingTools.Software LLC</span> does with any information we collect? Review the notice in full below.</p>
                    <div class="w-100 m-top"><h2>TABLE OF CONTENTS</h2></div>
                    <div class="hr"></div>
                    <ol>
                        <li><a href="#what-information-do-we-collect">WHAT INFORMATION DO WE COLLECT?</a></li>
                        <li><a href="#how-do-we-process-your-information">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                        <li><a href="#what-legal-bases-do-we-rely-on">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
                        <li><a href="#when-and-with-whom-do-we-share-your-personal-information">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                        <li><a href="#what-is-our-stance-on-third-party-websites">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a></li>
                        <li><a href="#do-we-use-cookies-and-other-tracking-technologies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                        <li><a href="#how-do-we-handle-your-social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
                        <li><a href="#is-your-information-transferred-internationally">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></li>
                        <li><a href="#how-long-do-we-keep-your-information">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                        <li><a href="#how-do-we-keep-your-information-safe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                        <li><a href="#do-we-collect-information-from-minors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                        <li><a href="#what-are-your-privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                        <li><a href="#controls-for-do-not-track-features">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                        <li><a href="#do-california-residents-have-specific-privacy-rights">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                        <li><a href="#do-virginia-residents-have-specific-privacy-rights">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                        <li><a href="#do-we-make-updates-to-this-notice">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                        <li><a href="#how-can-you-contact-us-about-this-notice">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                        <li><a href="#how-can-you-review-update-or-delete-the-data-we-collect-from-you">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                    </ol>
                    <ol>
                        <li id="what-information-do-we-collect">
                            <h2 class="p-bot">WHAT INFORMATION DO WE COLLECT?</h2>
                            <h3><span class="bold">Personal information you disclose to us</span></h3>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We collect personal information that you provide to us.</span></p>
                            <p>We collect personal information that you voluntarily provide to us when you <span class="purple">register on our website</span>, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                            <h3><span class="bold">Personal Information Provided by You</span></h3>
                            <p>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                            <ul>
                                <li><span class="purple">First and last name as per your entry</span></li>
                                <li><span class="purple">Email address</span></li>
                                <li><span class="purple">Physical address</span></li>
                                <li><span class="purple">Discord user name</span></li>
                                <li><span class="purple">Timezone</span></li>
                            </ul>
                            <h3><span class="bold">Sensitive Information</span></h3>
                            <p class="purple">We do not process sensitive information.</p>
                            <h3><span class="bold">Payment Data</span></h3>
                            <p>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by <span class="purple">Stripe Inc.</span>. You may find their privacy notice link(s) here: <a href="https://stripe.com/en-lu/privacy">https://stripe.com/en-lu/privacy</a>.</p>
                            <h3><span class="bold">Social Media Login Data</span></h3>
                            <p>We may provide you with the option to register with us using your existing social media account details, like your Discord, or other social media account. If you choose to register in this way, we will collect the information described in the section called <a href="#how-do-we-handle-your-social-logins">“HOW DO WE HANDLE YOUR SOCIAL LOGINS?”</a>below.</p>
                            <h3><span class="bold">Information automatically collected</span></h3>
                            <p><span class="bold italics">In Short:</span><span class="italics"> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</span></p>
                            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                            <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                            <p>The information we collect includes:</p>
                            <ul>
                                <li><span class="italics">Log and Usage Data</span>. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called “crash dumps”), and hardware settings).</li>
                                <li><span class="italics">Device Data</span>. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                                <li><span class="italics">Location Data</span>. We collect location data such as information about your device’s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
                            </ul>
                            <h3><span class="bold">Information collected from other sources</span></h3>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</span></p>
                            <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion. If you interact with us on a social media platform using your social media account (e.g., Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. Any personal information that we collect from your social media account depends on your social media account’s privacy settings.</p>
                        </li>
                        <li id="how-do-we-process-your-information">
                            <h2 class="p-bot">HOW DO WE PROCESS YOUR INFORMATION</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></p>
                            <p class="bold">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                            <ul>
                                <li><span class="bold">To facilitate account creation and authentication and otherwise manage user accounts</span>. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                                <li><span class="bold">To deliver and facilitate delivery of services to the user</span>. We may process your information to provide you with the requested service.</li>
                                <li><span class="bold">To respond to user inquiries/offer support to users</span>. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                                <li><span class="bold">To send administrative information to you</span>. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                                <li><span class="bold">To fulfill and manage your orders</span>. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
                                <li><span class="bold">To enable user-to-user communications</span>. We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                                <li><span class="bold">To request feedback</span>. We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                                <li><span class="bold">To send you marketing and promotional communications</span>. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see <a class="italics" href="#what-are-your-privacy-rights">“WHAT ARE YOUR PRIVACY RIGHTS?”</a> below).</li>
                                <li><span class="bold">To deliver targeted advertising to you</span>. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</li>
                                <li><span class="bold">To protect our Services</span>. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                                <li><span class="bold">To identify usage trends</span>. We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                                <li><span class="bold">To determine the effectiveness of our marketing and promotional campaigns</span>. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
                                <li><span class="bold">To save or protect an individual’s vital interest</span>. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
                            </ul>
                        </li>
                        <li id="what-legal-bases-do-we-rely-on">
                            <h2 class="p-bot">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</span></p>
                            <p class="bold italics underline">If you are located in the EU or UK, this section applies to you.</p>
                            <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
                            <ul>
                                <li><span class="bold">Consent</span>. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</li>
                                <li><span class="bold">Performance of a Contract</span>. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
                                <li><span class="bold">Legitimate Interests</span>. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to: 
                                    <ul>
                                        <li>Send users information about special offers and discounts on our products and services</li>
                                        <li>Develop and display personalized and relevant advertising content for our users</li>
                                        <li>Analyze how our services are used so we can improve them to engage and retain users</li>
                                        <li>Support our marketing activities</li> <li>Diagnose problems and/or prevent fraudulent activities</li>
                                        <li>Understand how our users use our products and services so we can improve user experience</li>
                                    </ul>
                                </li>
                                <li><span class="bold">Legal Obligations</span>. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                                <li><span class="bold">Vital Interests</span>. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
                            </ul>
                            <p>In legal terms, we are generally the “data controller” under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This privacy notice does not apply to the personal information we process as a “data processor” on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the “data controller” responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers’ privacy practices, you should read their privacy policies and direct any questions you have to them.</p>
                            <p class="bold italics underline">If you are located in Canada, this section applies to you.</p>
                            <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</p>
                            <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
                            <ul>
                                <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                                <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                                <li>For investigations and fraud detection and prevention</li>
                                <li>For business transactions provided certain conditions are met</li>
                                <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                                <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                                <li>If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse</li>
                                <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                                <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                                <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                                <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                                <li>If the information is publicly available and is specified by the regulations</li>
                            </ul>
                        </li>
                        <li id="when-and-with-whom-do-we-share-your-personal-information">
                            <h2 class="p-bot">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We may share information in specific situations described in this section and/or with the following categories of third parties.</span></p>
                            <p class="bold">Vendors, Consultants, and Other Third-Party Service Providers.</p>
                            <p>We may share your data with third-party vendors, service providers, contractors, or agents (“<span class="bold">third parties</span>”) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:</p>
                            <ul>
                                <li>Affiliate Marketing Programs</li>
                                <li>Cloud Computing Services</li>
                                <li>Communication & Collaboration Tools</li>
                                <li>Data Analytics Services</li>
                                <li>Data Storage Service Providers</li>
                                <li>Finance & Accounting Tools</li>
                                <li>Government Entities</li>
                                <li>Order Fulfillment Service Providers</li>
                                <li>Payment Processors</li>
                                <li>Performance Monitoring Tools</li>
                                <li>Product Engineering & Design Tools</li>
                                <li>Retargeting Platforms</li>
                                <li>Sales & Marketing Tools</li>
                                <li>Social Networks</li>
                                <li>Testing Tools</li>
                                <li>User Account Registration & Authentication Services</li>
                                <li>Website Hosting Service Providers</li>
                            </ul>
                            <p>We also may need to share your personal information in the following situations:</p>
                            <ul>
                            <li><span class="bold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li><span class="bold">Affiliates.</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
                            <li><span class="bold">Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                            <li><span class="bold">Other Users.</span> When you share personal information <span class="purple">(for example, by posting comments, contributions, or other content to the Services)</span> or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
                            <li><span class="bold">Offer Wall.</span> Our application(s) may display a third-party hosted “offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</li>
                        </ul>

                        </li>
                        <li id="what-is-our-stance-on-third-party-websites">
                            <h2 class="p-bot">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</span></p>
                            <p>The Services, <span class="purple">including our offer wall</span>, may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
                        </li>
                        <li id="do-we-use-cookies-and-other-tracking-technologies">
                            <h2 class="p-bot">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We may use cookies and other tracking technologies to collect and store your information.</span></p>
                            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</p>
                        </li>
                        <li id="how-do-we-handle-your-social-logins">
                            <h2 class="p-bot">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</span></p>
                            <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. If you log in using Facebook, we may also request access to other permissions related to your account, such as your friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission.</p>
                            <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
                        </li>
                        <li id="is-your-information-transferred-internationally">
                            <h2 class="p-bot">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We may transfer, store, and process your information in countries other than your own.</span></p>
                            <p>Our servers are located in <span class="purple">United States of America</span>. If you are accessing our Services from outside United States of America, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see “WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?” above), in <span class="purple">Europe</span>, and other countries.</p>
                            <p>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
                            <p>European Commission’s Standard Contractual Clauses:</p>
                            <p>We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations.</p>
                        </li>
                        <li id="how-long-do-we-keep-your-information">
                            <h2 class="p-bot">HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span></p>
                            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span class="purple">24 months past the termination of the user’s account</span>.</p>
                            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                        </li>
                        <li id="how-do-we-keep-your-information-safe">
                            <h2 class="p-bot">HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We aim to protect your personal information through a system of organizational and technical security measures.</span></p>
                            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
                        </li>
                        <li id="do-we-collect-information-from-minors">
                            <h2 class="p-bot">DO WE COLLECT INFORMATION FROM MINORS?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> We do not knowingly collect data from or market to children under 18 years of age.</span></p>
                            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>.</p>
                        </li>
                        <li id="what-are-your-privacy-rights">
                            <h2 class="p-bot">WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span></p>
                            <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <a href="#how-can-you-contact-us-about-this-notice">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”</a> below.</p>
                            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
                            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">https://edpb.europa.eu/about-edpb/about-edpb/members_en.</a></p>
                            <p>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html.</a></p>
                            <p></p>
                            <h3 class="bold underline">Withdrawing your consent:</h3>
                            <p>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <a href="#how-can-you-contact-us-about-this-notice">“HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”</a> below or updating your preferences.</p>
                            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
                            <p></p>
                            <h3 class="bold underline">Opting out of marketing and promotional communications:</h3>
                            <p>You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section <a href="#how-can-you-contact-us-about-this-notice">“HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”</a> below. You will then be removed from the marketing lists — however, we may still communicate with you, for example to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
                            <p></p>
                            <h3 class="bold">Account Information</h3>
                            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                            <ul>
                                <li>Log in to your account settings and update your user account.</li>
                                <li>Contact us using the contact information provided.</li>
                            </ul>
                            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
                            <p></p>
                            <h3 class="bold underline">Cookies and similar technologies:</h3>
                            <p>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
                            <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a></p>
                        </li>
                        <li id="controls-for-do-not-track-features">
                            <h2 class="p-bot">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
                        </li>
                        <li id="do-california-residents-have-specific-privacy-rights">
                            <h2 class="p-bot">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics"> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span></p>
                            <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
                            <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
                            <p></p>
                            <h3 class="bold">CCPA Privacy Notice</h3>
                            <p>The California Code of Regulations defines a “resident” as:</p>
                            <ol>
                                <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                                <li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                            </ol>
                            <p>All other individuals are defined as “non-residents.”</p>
                            <p>If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>
                            <p></p>
                            <h3 class="bold">What categories of personal information do we collect?</h3>
                            <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
                            <table>
                                <tr>
                                    <th>Category</th>
                                    <th>Examples</th>
                                    <th>Collected</th>
                                </tr>
                                <tr>
                                    <td>A. Identifiers</td>
                                    <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>B. Personal information categories listed in the California Customer Records statute</td>
                                    <td>Name, contact information, education, employment, employment history, and financial information</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>C. Protected classification characteristics under California or federal law</td>
                                    <td>Gender and date of birth</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>D. Commercial information</td>
                                    <td>Transaction information, purchase history, financial details, and payment information</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>E. Biometric information</td>
                                    <td>Fingerprints and voiceprints</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>F. Internet or other similar network activity</td>
                                    <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>G. Geolocation data</td>
                                    <td>Device location</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                                    <td>Images and audio, video or call recordings created in connection with our business activities</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>I. Professional or employment-related information</td>
                                    <td>Business contact details in order to provide you our services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>J. Education Information</td>
                                    <td>Student records and directory information</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>K. Inferences drawn from other personal information</td>
                                    <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>L. Sensitive personal information</td>
                                    <td>Account login information, drivers’ licenses, health data, precise geolocation, racial or ethnic origin, religious or philosophical beliefs, and sex life or sexual orientation</td>
                                    <td>NO</td>
                                </tr>
                            </table>
                            <p></p>
                            <h3 class="bold">How do we use and share your personal information?</h3>
                            <p></p>
                            <span class="purple">TradingTools.Software LLC</span> collects and shares your personal information through:
                            <ul>
                                <li>Targeting cookies/Marketing cookies</li>
                                <li>Social media cookies</li>
                                <li>Beacons/Pixels/Tags</li>
                                <li>Affiliate marketing tracking: <span class="purple">Trackdesk. Please see their privacy notice here:</span> <a href="https://trackdesk.com/privacy-policy">https://trackdesk.com/privacy-policy</a></li>
                            </ul>
                            <p>You may contact us by email at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>.</p>
                            <p>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
                            <h3 class="bold">Will your information be shared with anyone else?</h3>
                            <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</p>
                            <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be “selling” of your personal information.</p>
                            <p class="purple">TradingTools.Software LLC has not sold or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.</p>
                            <p></p>
                            <h3 class="bold">Your rights with respect to your personal data</h3>
                            <p class="underline">Right to request deletion of the data — Request to delete</p>
                            <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>
                            <p class="underline">Right to be informed — Request to know</p>
                            <p>Depending on the circumstances, you have a right to know:</p>
                            <ul>
                                <li>whether we collect and use your personal information;</li>
                                <li>the categories of personal information that we collect;</li>
                                <li>the purposes for which the collected personal information is used;</li>
                                <li>whether we sell or share personal information to third parties;</li>
                                <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
                                <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>
                                <li>the business or commercial purpose for collecting, sharing, or selling personal information; and</li>
                                <li>the specific pieces of personal information we collected about you.</li>
                            </ul>
                            <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
                            <p class="underline">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
                            <p>We will not discriminate against you if you exercise your privacy rights.</p>
                            <p class="underline">Right to Limit Use and Disclosure of Sensitive Personal Information</p>
                            <p class="purple">We do not process consumer’s sensitive personal information.</p>
                            <p class="underline">Verification process</p>
                            <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>
                            <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</p>
                            <p class="underline">Other privacy rights</p>
                            <ul>
                                <li>You may object to the processing of your personal information.</li>
                                <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
                                <li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</li>
                            </ul>
                        </li>
                        <li id="do-virginia-residents-have-specific-privacy-rights">
                            <h2 class="p-bot">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics">Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</span></p>
                            <p></p>
                            <h3 class="bold">Virginia CDPA Privacy Notice</h3>
                            <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
                            <p>“Consumer” means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
                            <p>“Personal data” means any information that is linked or reasonably linkable to an identified or identifiable natural person. “Personal data” does not include de-identified data or publicly available information.</p>
                            <p>“Sale of personal data” means the exchange of personal data for monetary consideration.</p>
                            <p>If this definition of “consumer” applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
                            <p>The information we collect, use, and disclose about you will vary depending on how you interact with <span class="purple">TradingTools.Software LLC</span> and our Services. To find out more, please visit the following sections above:</p>
                            <ul>
                                <li>1. <a href="#what-information-do-we-collect">WHAT INFORMATION DO WE COLLECT?</a></li>
                                <li>2. <a href="#how-do-we-process-your-information">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                                <li>4. <a href="#when-and-with-whom-do-we-share-your-personal-information">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                            </ul>
                            <p class="underline">Your rights with respect to your personal data</p>
                            <ul>
                                <li>Right to be informed whether or not we are processing your personal data</li>
                                <li>Right to access your personal data</li>
                                <li>Right to correct inaccuracies in your personal data</li>
                                <li>Right to request deletion of your personal data</li>
                                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                                <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
                            </ul>
                            <p class="purple">TradingTools.Software LLC has not sold any personal data to third parties for business or commercial purposes. TradingTools.Software LLC will not sell personal data in the future belonging to website visitors, users, and other consumers.</p>
                            <p class="underline">Exercise your rights provided under the Virginia CDPA</p>
                            <p>You may contact us by email at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>.</p>
                            <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
                            <p class="underline">Verification process</p>
                            <p>We may request that you provide additional information reasonably necessary to verify you and your consumer’s request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.</p>
                            <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
                            <p class="underline">Right to appeal</p>
                            <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">submit a complaint</a>.</p>
                        </li>
                        <li id="do-we-make-updates-to-this-notice">
                            <h2 class="p-bot">DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                            <p><span class="bold italics">In Short:</span><span class="italics">Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></p>
                            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                        </li>
                        <li id="how-can-you-contact-us-about-this-notice">
                            <h2 class="p-bot">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                            <p>If you have questions or comments about this notice, you may contact us by email at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a></p>
                            <p>If you are a resident in the European Economic Area, the “data controller” of your personal information is <span class="purple">TradingTools.Software LLC.</span> You can contact them directly regarding the processing of your information by <span class="purple">TradingTools.Software LLC</span>, by email at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a></p>
                            <p>If you are a resident in the United Kingdom, the “data controller” of your personal information is <span class="purple">TradingTools.Software LLC.</span>. You can contact them directly regarding the processing of your information by <span class="purple">TradingTools.Software LLC.</span>, by email at <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>.</p>
                            <p>If you have any further questions or comments, you may also contact us by post at the following corporate address:</p>
                            <p class="purple">TradingTools.Software LLC</p>
                            <a href="mailto:support@tradingtools.software">support@tradingtools.software</a>
                        </li>
                        <li id="how-can-you-review-update-or-delete-the-data-we-collect-from-you">
                            <h2 class="p-bot">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please send us an E-Mail to <a href="mailto:support@tradingtools.software">support@tradingtools.software</a></p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Offset scroll position to account for fixed header
    document.addEventListener("DOMContentLoaded", function() {
        var headerHeight = document.querySelector('nav').offsetHeight;
        var anchorLinks = document.querySelectorAll('a[href^="#"]');
        
        anchorLinks.forEach(function(anchor) {
            anchor.addEventListener('click', function(e) {
                console.log("custom # jump");
                e.preventDefault();
                var targetId = this.getAttribute('href').slice(1);
                var targetElement = document.getElementById(targetId);
                var targetPosition = targetElement.offsetTop - headerHeight;
                
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            });
        });
    });

    export default {
        name: 'PrivacyPolicy-Component',
    }
</script>

<style scoped>
    *{
        font-size:14px;
    }
    h1{
        font-size: 24px;
    }
    h2{
        font-size: 18px;
    }
    h3{
        font-size:16px;
    }
    p{
        padding-top:5px;
        padding-bottom:5px;
    }
    ul{
        padding:10px;
        padding-left:20px;
        list-style-type:circle;
        text-align:left;
        li{
            padding-top:5px;
            padding-bottom:5px;
            padding-left:5px;
        }
    }
    ol{
        padding:10px;
        padding-left:20px;
        list-style-type:decimal;
        text-align:left;
        li{
            padding-top:5px;
            padding-bottom:5px;
            padding-left:5px;
        }
    }
    a{
        color:blue;
    }
    table{
        width:100%;
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid black;
        tr{
            border: 1px solid black;
            th{
                border: 1px solid black;
                padding: 5px;
            }
            td{
                border: 1px solid black;
                padding: 5px;
                white-space: pre-wrap;
            }
            .no-wrap{
                white-space: nowrap;
            }
        }
    }
    .hr{
        width:100%;
        height:1px;
        margin:1px;
        background-color:black;
    }
    .bold{
        font-weight:bold;
    }
    .italics{
        font-style:italic;
    }
    .underline{
        text-decoration:underline;
    }
    .purple{
        color:rgb(114,67,242);
    }
    .p-top{
        padding-top:10px;
    }
    .p-bot{
        padding-bottom:10px;
    }
    .m-top{
        margin-top:10px;
    }
    .m-bot{
        margin-bottom:10px;
    }
    .w-100{
        width:100%;
    }
    #pp{
        display:flex;
        flex-wrap: wrap;
        padding-top:30px;
        padding-bottom:30px;
        justify-content: center;
        margin:0px 20px;
        text-align:left;
    }
    
    #pp_container{
        max-width: 700px;
        /* height: 80%; */
        margin: 20px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:space-around;
        background-color: #eff2f6;
        box-shadow: 0px 0px 12px #999;
        /* background-color: #96c2ed; */
        /* background-color: #69a8e7;
        box-shadow: 0px 0px 10px #96c2ed;
        color:#eee;
        text-shadow: 1px 1px 2px #777; */
    }
    
    #pp_wrapper{
        background-color: #cecee0;
        /* background-color: dodgerblue; */
        /* background-color: #f3f2ef; */
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height:100%;
        justify-content: center;
        text-align: center;
        align-items: flex-start;
    }
    .main-pp-content{
        padding-top:10px;
        display:flex;
        width:100%;
        flex-wrap: wrap;
    }
</style>