<template>
  <div id="not_found_wrapper">
    <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
  </div>
  
</template>
<script>
  
  import { mapMutations } from 'vuex'
  export default {
      name: 'vue-not-found',
      mounted(){
          this.setSidebarHidden(true)
      },
      methods:{
          ...mapMutations(['setSidebarHidden'])
      }
  }
</script>
<style scoped>
  #not_found_wrapper{
    min-height: 100%;
  }
</style>