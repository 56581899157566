<template>
    <div id="sign_up_wrapper">
        <div id="sign_up_container">
            <div id="title" class="bold">Create Your Account</div>
            <div id="subtitle" class="bold glowred create_account_msg">To use our software, you must create an account. Sign up below to get started.</div>
            <div id="sign_up_names">
                <div id="firstname_container">
                    <div id="first_name" class="bold">First Name*</div>
                    <input type="text" v-model="first_name"/>
                    <div class="error error_fn" :class="{error_active_fn:first_name_err}">{{ first_name_err_msg }}</div>
                </div>
                <div id="lastname_container">
                    <div id="last_name" class="bold">Last Name*</div>
                    <input type="text" v-model="last_name"/>
                    <div class="error error_ln" :class="{error_active_ln:last_name_err}">{{ last_name_err_msg }}</div>
                </div>
            </div>
            <div id="email_container">
                <div id="email" class="bold">Email*</div>
                <input type="text" v-model="email"/>
                <div class="error error_em" :class="{error_active_em:email_err}">{{ email_err_msg }}</div>
            </div>
            <div id="discord_container">
                <div id="discord" class="bold">Discord Username</div>
                <input type="text" v-model="discord_username"/>
                <div class="error error_dsc" :class="{error_active_dsc:discord_username_err}">{{ discord_username_err_msg }}</div>
            </div>
            <div id="timezone_container">
                <div id="timezone" class="bold">Timezone</div>
                <select id="timezone_select" v-model="timezone">
                    <option disabled value="">Please select one</option>
                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                    <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                    <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                    <option value="America/Managua">(GMT-06:00) Central America</option>
                    <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                    <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                    <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                    <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                    <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                    <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                    <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                    <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                    <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                    <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                    <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                    <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                    <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                    <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                    <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                    <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                    <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                    <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                    <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                    <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                    <!-- Add more options for other timezones -->
                </select>
                <div class="error error_tz" :class="{error_active_tz:timezone_err}">{{ timezone_err_msg }}</div>
            </div>
            <div id="password_container">
                <div id="password" class="bold">Password*</div>
                <input type="password" v-model="password"/>
                <div class="error error_pw" :class="{error_active_pw:password_err}">{{ password_err_msg }}</div>
                <div class="fineprint">Password must be between 8-32 characters and include at least one letter, one number and at least one of these special characters !@#$%^&*.</div>
            </div>
            <div id="confirm_password_container">
                <div id="confirm_password" class="bold">Confirm Password*</div>    
                <input type="password" v-model="confirm_password"/>
                <div class="error error_cfpw" :class="{error_active_cfpw:confirm_password_err}">{{ confirm_password_err_msg }}</div>
                <div id="tts_policies">
                    <div id="tos_container">
                        <div id="checkbox_container">
                            <input class="checkbox" type="checkbox" v-model="tos_agree"/>
                            <div id="tos_text">I have read and agree to TradingTools.Software's</div>
                        </div>                    
                    </div>
                    <div id="tos_policies_container">
                        <router-link to="/terms-of-service">Terms of Service</router-link> 
                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                    </div>
                </div>
                <div class="error error_tos" :class="{error_active_tos:tos_agree_err}">{{ tos_agree_err_msg }}</div>
            </div>        
            <button id="submit_button" @click="register_user">Submit</button>
            <div id="utilities">
                <div id="utilities_title">Already Have an Account?</div>
                <div id="forgot_password">
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                </div>
                <div id="log_in">
                    <router-link to="/log-in">Log In</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

    export default {
        name: 'Signup-Component',
        data: () => ({
            first_name:'', first_name_err:false, first_name_err_msg:'',
            last_name:'', last_name_err:false, last_name_err_msg:'',
            email:'', email_err:false, email_err_msg:'',
            discord_username:'', discord_username_err:false, discord_username_err_msg:'',
            timezone:'', timezone_err:false, timezone_err_msg:'',
            password:'', password_err:false, password_err_msg:'',
            confirm_password:'', confirm_password_err:false, confirm_password_err_msg:'',
            tos_agree:false, tos_agree_err:false, tos_agree_err_msg:''
        }),
        methods: {
            async register_user(){
                //Reset error states to false so they hide on resubmitting
                this.first_name_err = false;
                this.last_name_err = false;
                this.email_err = false;
                this.discord_username_err = false;
                this.timezone_err = false;
                this.password_err = false;
                this.confirm_password_err = false;
                this.tos_agree_err = false;

                const url = `https://${document.location.hostname}/api/signup`;
                const options = {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        "details":{
                            "first_name":this.first_name,
                            "last_name":this.last_name,
                            "email":this.email,
                            "discord_username":this.discord_username,
                            "timezone":this.timezone,
                            "password":this.password
                        }
                    })
                };
                console.log("Preparing to fetch");
                //todo - @JZ - finish the rest of the client side error checking
                if(!this.tos_agree){
                    this.tos_agree_err = true;
                    this.tos_agree_err_msg = "You must agree to the terms to continue.";
                    return;
                }
                var upper_case_regex = /(?=.*[A-Z])/;
                var lower_case_regex = /(?=.*[a-z])/;
                var digit_regex = /(?=.*[0-9])/;
                var special_character_regex = /([^A-Za-z0-9])/;

                if(this.password.length < 8){
                    this.password_err = true;
                    this.password_err_msg = "Password must be at least 8 characters";
                    return;
                }
                if(this.password.length > 32){
                    this.password_err = true;
                    this.password_err_msg = "Password must be less than 32 characters";
                    return;
                }
                if (!upper_case_regex.test(this.password)){
                    this.password_err = true;
                    this.password_err_msg = "Password must have at least one upper case letter.";
                    return;
                }
                if (!lower_case_regex.test(this.password)){
                    this.password_err = true;
                    this.password_err_msg = "Password must have at least one lower case letter.";
                    return;
                } 
                if (!digit_regex.test(this.password)){
                    this.password_err = true;
                    this.password_err_msg = "Password must have at least one number.";
                    return;
                }
                if(!special_character_regex.test(this.password)){
                    this.password_err = true;
                    this.password_err_msg = "Password must have at least one special character - !@#$%^&*";
                    return;
                }
                if(this.confirm_password != this.password){
                    this.confirm_password_err = true;
                    this.confirm_password_err_msg = "Passwords do not match.";
                    return;
                }
                // if(this.discord_username == ""){
                //     this.discord_username_err = true;
                //     this.discord_username_err_msg = "Discord username is required.";
                //     return;
                // }
                // if(this.timezone == ""){
                //     this.timezone_err = true;
                //     this.timezone_err_msg = "Timezone is required.";
                //     return;
                // }
                var response = await fetch(url,options);
                const json_response = await response.json();
                console.log("Showing json response below");
                console.log(json_response);
                if(response.ok){
                    const verify_url = `https://${document.location.hostname}/api/verify/set-codes-and-send-email`;
                    const verify_options = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        body: JSON.stringify({
                            "details":{
                                "email_hash":json_response.email_hash
                            }
                        })
                    };
                    var verify_response;
                    var verify_json_response;
                    try {
                        verify_response = await fetch(verify_url,verify_options);
                        verify_json_response = await verify_response.json();
                        console.log("Showing verify json response below");
                        console.log(verify_json_response);
                    } catch (error) {
                        console.error(error);
                    }
                    if(verify_response.ok){
                        router.push({name:'ConfirmEmail',params:{email_hash:json_response.email_hash}});
                    }else{
                        switch(verify_json_response.code){
                            case 11://email already verified    
                                this.email_err = true;
                                this.email_err_msg = "Email address has already been verified."
                                break;
                            default:
                                break;
                        }
                    }
                }else{
                    switch (json_response.code) {
                        case 1://email in use
                            this.email_err = true;
                            this.email_err_msg = "Email address is in use."
                            break;
                        case 2:                   
                            break;
                        case 3://email malformed
                            this.email_err = true;
                            this.email_err_msg = "Email address is in incorrect. Please use x@x.xx format."
                            break;
                        case 4://input too short
                            if(json_response.field == "FIRST_NAME"){
                                this.first_name_err = true;
                                this.first_name_err_msg = "First Name must be at least one character";
                            }
                            if(json_response.field == "LAST_NAME"){
                                this.last_name_err = true;
                                this.last_name_err_msg = "Last Name must be at least one character";
                            }
                            if(json_response.field == "EMAIL"){
                                this.email_err = true;
                                this.email_err_msg = "Email must be at least 6 characters (x@x.xx)";
                            }
                            if(json_response.field == "PASSWORD"){
                                this.password_err = true;
                                this.password_err_msg = "Password must be at least 8 characters";
                            }
                            break;
                        case 5://input too long
                            if(json_response.field == "FIRST_NAME"){
                                this.first_name_err = true;
                                this.first_name_err_msg = "First Name must be less than 50 characters";
                            }
                            if(json_response.field == "LAST_NAME"){
                                this.last_name_err = true;
                                this.last_name_err_msg = "Last Name must be less than 50 characters";
                            }
                            if(json_response.field == "EMAIL"){
                                this.email_err = true;
                                this.email_err_msg = "Email must be at less than 256 characters";
                            }
                            if(json_response.field == "PASSWORD"){
                                this.password_err = true;
                                this.password_err_msg = "Password must be less than 32 characters";
                            }
                            break;
                        case 6://no upper case letters
                            this.password_err = true;
                            this.password_err_msg = "Password must have at least one upper case letter"
                            break;
                        case 7://no lower case letters
                            this.password_err = true;
                            this.password_err_msg = "Password must have at least one lower case letter"
                            break;
                        case 8://no digits
                            this.password_err = true;
                            this.password_err_msg = "Password must have at least one number"
                            break;
                        case 9://no special characters
                            this.password_err = true;
                            this.password_err_msg = "Password must have at least one special character - !@#$%^&*"
                            break;
                        case 27:
                            this.discord_username_err = true;
                            this.discord_username_err_msg = "Discord username must be greater than 1 character.";
                            break;
                        case 28:
                            this.discord_username_err = true;
                            this.discord_username_err_msg = "Discord username must be less than 32 characters.";
                            break;
                        case 29:
                            this.timezone_err = true;
                            this.timezone_err_msg = "Timezone must be less than 255 characters.";
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }
</script>
<style scoped>
#title{
    font-size:24px;
    width:100%;
    text-align: center;
    font-weight: bold;
    margin-top:10px;
}
.error_active_em,.error_active_fn,.error_active_ln,.error_active_pw,.error_active_cfpw,.error_active_tos,.error_active_dsc,.error_active_tz{
    display:flex !important;
}
#dashboard_menu{
    display: none;
}
.error{
    color: white;
    display:none;
    width:100%;
    margin-top:5px;
    margin-bottom:5px;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    background-color: #e10000;
    border-radius: 20px;
    padding: 4px 14px;
    text-align: center;
    /* white-space: nowrap; */
    overflow:hidden;
}
@keyframes glowred{
    from{
        color:#F00;
        -webkit-text-stroke: 1px rgb(255, 225, 0);
        text-shadow: 0px 0px 10px goldenrod;
    }
    to{
        color:#000;
        -webkit-text-stroke: 1px rgba(0,0,0,0);
        text-shadow: 0px 0px 0px goldenrod;
    }
}
.glowred{
    animation-name: glowred;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 3;
    animation-direction: alternate;
    
}

#checkbox_container{
    display: flex;
    align-items: center;
    width: 100%;
}
#tos_container{
    display:flex;
    flex-wrap: wrap;
    width:100%;
}
#tos_container h3{
    width:100%;
}
#tos_policies_container{
    display: flex;
    width:100%;
    justify-content: flex-start;
    font-size: 11px;
    margin-top:8px;
    align-items: center;
}
#tos_policies_container a:first-child{
    margin-right:10px;
}
#firstname_container,#lastname_container,#email_container,#password_container,#confirm_password_container,#discord_container,#timezone_container{
    width:100%;
    display: flex;
    flex-wrap: wrap;
}
#firstname_container,#lastname_container{
    align-content: flex-start;
}
#sign_up_wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow:1;
    align-items: flex-start;
    justify-content: center;
    background-color: #cecee0;
}
#sign_up_container{
    width: 100%;
    /* height: 100%; */
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content:flex-start;
    margin: 10px;
    padding:10px;
    background-color: #eff2f6;
    box-shadow: 0px 0px 12px #999;
}

input{
    width:100%;
    height:40px;
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    padding-left:10px;
    font-size:16px;
    align-items: center;
    color:#333;
}
.bold{
    font-weight:bold;
}

.create_account_msg{
    margin:10px;
    text-align: center;
}
#first_name,#last_name,#email,#password,#confirm_password,#discord,#timezone{
    margin-top:10px;
    margin-bottom:4px;
}
#timezone_select{
    width:100%;
}
#tos_text{
    font-size: 11px;
    padding-left: 6px;
}
.fineprint{
    margin:10px 0px;
    width:100%;
    height:16px;
    font-size:11px;
}

#tts_policies{
    display: flex;
    width:100%;
    /* height: 70px; */
    flex-wrap: wrap;
    align-content: center;
    margin-top:10px;
}
#tos_container h3{
    padding-left:10px;
}
#submit_button{
    cursor:pointer;
}
#submit_button:hover{
    background-color: rgb(63, 158, 253);
}
button {
    width: 100%;
    height: 40px;
    background-color:rgb(121, 188, 255);
    border: 1px solid rgb(95,167,240);
    font-weight:bold;
    color: #FFF;
    margin: 20px 0px;
    border-radius: 4px;
}

#forgot_password{
    font-size:11px;
    display:flex;
    /* background-color:red; */
    justify-content: left;
}

a{
    color: rgb(95, 167, 240);
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}
a:visited{
    color:rgb(95 167 240)
}

#utilities{
    display:flex;
    flex-wrap: wrap;
    width: 100%;
}
#utilities_title{
    font-size:12px;
    flex-grow:1;
    font-weight:bold;
}
#log_in{
    margin-top:10px;
    font-size:11px;
    display:flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
}

#sign_up_names{
    width:100%;
    display: flex;
    flex-wrap: wrap;
}

.checkbox{
    width:20px;
    height:20px;
    display: inline;
}
 /* Tablet menu */
@media all and (min-width: 700px){

    #sign_up_names{
        justify-content: space-between;
    }
    #firstname_container{
        width:48%;
    }
    #lastname_container{
        width:48%;
    }
    #tts_policies{
        flex-wrap:nowrap;
        align-items: center;
    }
    #tos_policies_container{
        margin-top:0;
        height:100%;
    }
    #sign_up_container{
        display:flex;
        max-width:700px;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:space-around;
        padding:20px;
    }
}
/* @media all and (min-width: 960px){
    
} */
</style>