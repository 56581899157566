<template>
    <Products></Products>
    <router-view />
</template>

<script>
    import Products from '@/components/products.vue'
    export default {
        name: 'vue-products',
        components: {
            Products
        }
    }
</script>

<style>
</style>