<template>    
    <ConfirmEmail :hash="hash" :email_hash="email_hash"></ConfirmEmail>
    <router-view />    
</template>

<script>
    import ConfirmEmail from '@/components/confirmemail.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-confirm-email',
        components: {
            ConfirmEmail
        },
        props: {
            hash: {
                type: String,
                required: false
            },
            email_hash: {
                type: String,
                required: true
            }
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>