<template>
    <Profile></Profile>
    <router-view />
</template>

<script>
    import Profile from '@/components/profile.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-profile',
        components: {
            Profile
        },
        mounted(){
            this.setSidebarHidden(false);
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>