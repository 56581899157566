<template>
    <PP></PP>
    <router-view />
</template>

<script>
    import PP from '@/components/privacypolicy.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-privacy-policy',
        components: {
            PP
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style scoped>
</style>