<template>
    <div id="license_key_wrapper">
        <div id="license_key_container">            
            <div id="license_key_main">
                <div id="license_key_title_area">
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-key" /></div>
                    <div class="title">License Keys</div>
                </div>
                <div id="input_optimizer_div">
                    <div id="input_optimizer_title">TradingView Input Optimizer</div>
                    <div id="input_optimizer_action">
                        <div id="input_optimizer_buy" :class="{hidden:new_tvio_buyer==false}"><a href="#" @click.prevent="showSubscriptionModal()"><button>Buy Now!</button></a></div>
                        <div id="input_optimizer_key_area" :class="{hidden:new_tvio_buyer==true}">
                            <div id="io_key_area">
                                <div id="io_key">
                                    <div id="io_product_key">{{licensekey}}</div>
                                    <div id="io_hidden_toggle" @click="toggleKeyVisibility">
                                        <font-awesome-icon v-if="key_visibility" icon="fa-solid fa-eye-slash" />
                                        <font-awesome-icon v-if="!key_visibility" icon="fa-solid fa-eye" />
                                    </div>
                                </div>
                                <div class="copy_key" @click="copyKey"><font-awesome-icon icon="fa-solid fa-copy" />
                                    <div class="copy_feedback" :class="{copy_animation:copy_animation==true}">Copied!</div>
                                </div>
                            </div>
                            <div id="io_expiration_area">
                                <div id="io_expire_date"> <span :class="{inactive:license_state_tvio=='deactivated'}">{{expire_text}}</span><span class="bold" :class="{hidden:license_state_tvio=='deactivated'}">{{tvio_expiration_date}}</span></div>
                                <div id="io_renew" :class="{hidden:license_state_tvio=='active'}"><a href="#" @click.prevent="showSubscriptionModal()"><button>Renew Now!</button></a></div>
                                <div id="io_update_notify_renewal"><input type="checkbox" :checked="notify_renewal" @change="$event => updateNotifyRenewal($event)"/>Notify via email upon renewal</div>
                            </div>
                        </div>
                    </div>
                    <div class="h_divider"></div>
                    <div id="license_sessions_container_tvio">
                        <div id="license_sessions_tvio_title">License Sessions: ({{ activeSessions }} / {{ totalAvailableActiveSessions }} In Use)</div>
                        <div id="license_sessions_tvio_info"><span class="tvio_extra_sessions_label">{{ purchasedSessions }} Extra Session Slots Purchased</span><span :class="{hidden:new_tvio_buyer==true}"><a :href="tvio_addon_buyurl"><button>Buy More</button></a></span></div>
                        <div class="license_session" v-for="(item, index) in license_info_tvio.licenseSessions" :key="item.uid">
                            <div class="license_session_name"><span class="machine_name">{{ (index+1) }}. {{ item.uid }}</span></div>
                            <div class="license_session_actions">
                                <span class="active_toggle_text">Active:</span><input :name="item.uid" class="checkbox" type="checkbox" :checked="item.active" @change="updateLicenseSession(item.uid,$event)"/>
                                <div class="trash" :name="item.uid" @click="deleteLicenseSession(item.uid)"><font-awesome-icon icon="fa-solid fa-trash-can" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal @close="toggleModal" :modalActive="modalActive">
            <template #content>
                <h1>Would you like to purchase a monthly* or yearly subscription (save 100 USD)?</h1>
            </template>
            <template #footer>
                <div>
                    <button @click="toggleModal" type="button" class="cancelButton">Cancel</button>
                    <p>* Minimum subscription period is 6 months</p>
                </div>
                <div>
                    <button @click="buyMonthly" type="button" class="monthlyButton">Monthly</button>
                    <button @click="buyYearly" type="button" class="yearlyButton">Yearly</button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import moment from 'moment'
    import { DateTime } from 'luxon'
    import Modal from '@/components/modal.vue'
    import {ref} from 'vue'
    export default {
        name : "License",
        components: {
            Modal,
        },
        setup(){
            console.log('dashboard setup');
            const modalActive = ref(false);

            const toggleModal = () => {
                modalActive.value = !modalActive.value;
            }

            return {modalActive, toggleModal};
        },
        methods:{
            /**
             * Deletes a license session.
             *
             * @param {string} uid - The unique identifier of the license session.
             * @return {void} 
             */
            deleteLicenseSession: async function(uid){
                console.log("deleteLicenseSession()")
                console.log(`uid: ${uid}`);
                let text = `Are you sure you want to delete ${uid}?`;
                if (confirm(text) == false) {
                    return;
                }
                let json_to_send = "";
                json_to_send = {
                    "details": {
                        "uid": uid,
                    }
                };
                const url = `https://${document.location.hostname}/api/security/delete-license-session`;
                const options = {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify(json_to_send)
                };
                try{
                    var response = await fetch(url,options);
                    
                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if(response.ok){
                        this.license_info_tvio.licenseSessions = this.license_info_tvio.licenseSessions.filter((obj) => {
                            return obj.uid != uid;
                        });
                        console.log(`License Session ${uid} deleted successfully...`);
                    }else{
                        switch (json_response.code) {
                            case 22://@JZ - License session not found
                                // logger.error(`License session not found (tried id: ${uid})`);
                                console.error(`License session not found (tried id: ${uid})`);
                                break;
                        }
                    }
                }catch(error){
                    // logger.error(`Try-catch error calling update_license_session API (tried id: ${uid})`);
                    console.error(`Try-catch error calling update_license_session API (tried id: ${uid})`);
                }
            },
            updateNotifyRenewal: async function(event){
                console.log("updateNotifyRenewal()");
                let active = event.target.checked;
                console.log(active);

                const url = `https://${document.location.hostname}/api/profile/update-notify-renewal/${(active)?"1":"0"}`;
                const options = {
                    method: "PUT"
                };
                try {
                    var response = await fetch(url,options);

                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if(response.ok){
                        console.log("Notify Renewal updated successfully...");
                    }else{
                        console.error("Notify Renewal failed...");
                    }
                } catch (error) {
                    console.error(`Try-catch error calling update_notify_renewal API`);
                }
            },
            updateLicenseSession: async function(uid,event){
                console.log("updateLicenseSession()");
                console.log(`uid: ${uid}`);
                let active = event.target.checked;
                console.log(active);
                
                let json_to_send = "";
                json_to_send = {
                    "details": {
                        "uid": uid,
                        "active": active
                    }
                };
                const url = `https://${document.location.hostname}/api/security/update-license-session`;
                const options = {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify(json_to_send)
                };
                try{
                    var response = await fetch(url,options);
                    
                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if(response.ok){
                        console.log(`License Session ${uid} updated successfully...`);
                    }else{
                        switch (json_response.code) {
                            case 22://@JZ - License session not found
                                // logger.error(`License session not found (tried id: ${uid})`);
                                console.error(`License session not found (tried id: ${uid})`);
                                break;
                            
                            case 23://@JZ - Too many license sessions
                                event.target.checked = false;
                                alert(`You have too many active sessions (Current active session capacity: ${json_response.session_limit}), please unselect other active sessions or you can purchase more session capacity if needed.`);
                                // logger.error(`Too many active license sessions (tried id: ${uid})`);
                                console.error(`Too many active license sessions (tried id: ${uid})`);
                                break;
                        }
                    }
                }catch(error){
                    // logger.error(`Try-catch error calling update_license_session API (tried id: ${uid})`);
                    console.error(`Try-catch error calling update_license_session API (tried id: ${uid})`);
                }
            },
            toggleKeyVisibility: function(){
                this.key_visibility = !this.key_visibility;
            },
            copyKey: function(){
                this.copy_animation = true;
                setTimeout(() => {
                    this.copy_animation = false;
                },1000);
                navigator.clipboard.writeText(this.license_info_tvio.key);
            },
            handleUpdates: function(){
                //@JA - Read session state to determine if buy disabled is true.
                //@JA - It's determined to be true IF a license is found for the user.
                console.log("Printing Licenses from license_keys.vue...");
                console.log(this.licenses);
                var filteredLicenses;
                try {
                    filteredLicenses = this.licenses.filter(function (el){
                        return (el.product_code == '1' || el.product_code == '3' || el.product_code == '4')
                    });
                    if(filteredLicenses.length > 0){
                        var filteredLicense = filteredLicenses[0];
                        this.notify_renewal = filteredLicense.user.profile.notify_renewal;
                        this.license_info_tvio = filteredLicense;
                        console.log('Filtered License Below:');
                        console.log(filteredLicense);
                        if(filteredLicense.active == true){
                            let license_expiration_date = moment.unix(this.license_info_tvio.subscriptions[0].current_period_end_timestamp);
                            let current_date = moment();
                            this.new_tvio_buyer = false;
                            if(current_date.isAfter(license_expiration_date)){
                                this.license_state_tvio = 'expired';
                                this.expire_text = 'Expired: ';
                            }else{
                                this.license_state_tvio = 'active';
                                this.expire_text = 'Expires: ';
                            }
                        }else{
                            this.new_tvio_buyer = false;
                            this.license_state_tvio = 'deactivated';
                            this.expire_text = 'Inactive';
                        }
                        if(this.license_info_tvio.subscriptions[0].stripe_subscriptionID == null || this.license_info_tvio.subscriptions[0].stripe_subscriptionID == "" || typeof this.license_info_tvio.subscriptions[0].stripe_subscriptionID == "undefined"){
                            console.log("Setting buy_or_bill_url to BUY");
                            this.buy_or_bill_url='buy'; //@JA - This was created through the CLS API to manually create a license in this situation.
                        }else{
                            console.log("Setting buy_or_bill_url to BILLING");
                            this.buy_or_bill_url='billing';
                        }
                    }else{
                        this.new_tvio_buyer=true;
                    }
                } catch (error) {
                    this.new_tvio_buyer = true;
                    console.error(error);
                }
            },
            showSubscriptionModal: function(){
                this.toggleModal(); //@JA - This will make the modal visible
            },
            buyMonthly : function(){
                const currentBaseUrl = window.location.origin;
                window.location.href = `${currentBaseUrl}/optimizer/stripe/buy-license?price=monthly`;
            },
            buyYearly: function(){
                const currentBaseUrl = window.location.origin;
                window.location.href = `${currentBaseUrl}/optimizer/stripe/buy-license?price=yearly`;
            }
        },
        computed: {
            ...mapState(['loggedInEmail','accountID','licenses','sessionReady']),
            tvio_expiration_date: function(){
                // return moment.unix(this.license_info_tvio.subscriptions[0].current_period_end_timestamp).format("MMMM Do, YYYY");
                if(this.license_info_tvio.subscriptions.length > 0){
                    console.log(this.license_info_tvio.subscriptions);
                    return DateTime.fromSeconds(this.license_info_tvio.subscriptions[0].current_period_end_timestamp).toLocaleString(DateTime.DATETIME_FULL);
                }else{
                    return "";
                }
            },
            billingurl: function(){
                // return process.env.VUE_APP_BILLING_URL+'?prefilled_email='+this.loggedInEmail;
                return "/stripe/billing";
            },
            tvio_buyurl: function(){
                //return process.env.VUE_APP_BUY_URL+'?prefilled_email='+this.loggedInEmail+'&client_reference_id='+this.accountID
                return "/optimizer/stripe/buy-license";
            },
            tvio_addon_buyurl: function(){
                return "/optimizer/stripe/buy-license-addon";
            },
            licensekey: function(){
                if(this.key_visibility==true){
                    return this.license_info_tvio.key;
                }else{
                    return "*****-*****-*****-*****";
                }
            },
            activeSessions: function(){
                let active_count = 0;
                for(let i = 0; i < this.license_info_tvio.licenseSessions.length; i++){
                    if(this.license_info_tvio.licenseSessions[i].active == true){
                        active_count++;
                    }
                }
                return active_count;
            },
            totalAvailableActiveSessions: function(){
                if(this.license_info_tvio.licenseAddons.length > 0){
                    return (parseInt(this.license_info_tvio.licenseAddons[0].value) + 5);
                }else{
                    return 5;
                }
            },
            purchasedSessions: function(){
                if(this.license_info_tvio.licenseAddons.length > 0){
                    return this.license_info_tvio.licenseAddons[0].value;
                }else{
                    return 0;
                }
            }
        },
        watch: {
            licenses: {
                handler(newValue, oldValue) {
                    console.log("License vuex change detected");
                    this.handleUpdates(newValue,oldValue);
                },
                deep: true,
                immediate:true,
            },
        },
        data: () => ({
            copy_animation: false,
            buy_or_bill_url:'buy',
            key_visibility: false,
            expire_text:'Expires:',
            license_state_tvio:'expired',
            new_tvio_buyer:false,
            notify_renewal:true,
            license_info_tvio:{
                // key: "XXXXX-XXXXX-LLHPV-YD4DA",
                key: "",
                subscriptions:[
                    // {
                    //     "current_period_end_timestamp":"1677998000"
                    // }
                ],
                licenseSessions:[
                    // {
                    //     id:1,
                    //     uid:"Mac:91c06352-3307-5cc1-a6c6-7561296d1670",
                    //     active:true,
                    //     license_id:1
                    // },
                    // {
                    //     id:1,
                    //     uid:"Mac2:81c06352-3307-5cc1-a6c6-7561296d1670",
                    //     active:false,
                    //     license_id:1
                    // },
                    // {
                    //     id:1,
                    //     uid:"Mac3:71c06352-3307-5cc1-a6c6-7561296d1670",
                    //     active:true,
                    //     license_id:1
                    // }
                ],
                licenseAddons:[
                    // {
                    //     active:true,
                    //     value:"6"
                    // }
                ]
            }
        }),
    }
</script>
<style lang="scss" scoped>
    .modal-content{
        display:flex;
        flex-direction:column;

        h1,p {
            margin-bottom:12px;
        }

        h1 {
            font-size:32px;
        }
    }
    .modal-footer{
        button{
            padding:10px 15px;
            // border:1px #0080ff solid;
            border:none;
            font-size:16px;
            color:#FFF;
            cursor:pointer;
            font-weight: bold;
            text-shadow: 1px 1px 2px #555;
            text-decoration: none;
            background: transparent;
            &:hover{
                background-color: #0080ff;
            }
        }
        p {
            padding-top:5px;
            font-size: 10px;
            font-style:italic;
        }
        .cancelButton{
            background-color: crimson;
        }
        .monthlyButton{
            background-color: rgb(96, 95, 95);
        }
        .yearlyButton{
            background-color: rgb(96, 95, 95);
            margin-left:10px;
        }
    }
    .h_divider{
        border-top: 1px solid #cecee0;
        height:1px;
        width:calc(100% + 20px);
        margin: 6px 0 6px -10px;
    }
    #io_update_notify_renewal{
        display: flex;
        height: 20px;
        align-items: center;
        font-size: 12px;
    }
    #io_update_notify_renewal input{
        margin-right: 4px;
        cursor: pointer;
    }
    #license_sessions_tvio_title{
        display: flex;
        font-size: 14px;
        font-weight:bold;
        justify-content: center;
        padding-top:6px;
        margin-bottom:6px;
        color:#393f47;
    }
    #license_sessions_tvio_info{
        display: flex;
        font-size: 12px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        margin-bottom:12px;
        color:#393f47;
    }
    .tvio_extra_sessions_label{
        margin-right:6px
    }
    .license_session{
        display:flex;
        flex-direction:row;
        width:100%;
        /* background-color: yellow; */
        justify-content: space-between;
        align-items:center;
        padding:4px 0;
    }
    .active_toggle_text{
        font-size: 12px;
    }
    .checkbox{
        margin-left:2px;
        margin-right:10px;
        cursor:pointer;
    }
    .trash{
        cursor: pointer;
    }
    .license_session_name{
        display:flex;
        font-size: 12px;
        margin-right: 8px;
    }
    .machine_name{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }
    .license_session_actions{
        display:flex;
        /* background-color: orange; */
        align-items: center;
    }
    
    #license_key_wrapper{
        display:flex;
        flex-grow: 1;
    }
    #license_key_container{        
        background-color: #cecee0;
        width: 100%;
        height: 100%;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:flex-start;
        justify-content: center;
    }
    #license_key_main{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #license_key_title_area{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 10px 0;
        color:#393f47;
        width:100%;
        max-width: 720px;
    }
    .icon{
        font-size:24px;
        padding-right:5px;
    }
    .title{
        font-size:24px;
        font-weight: bold;
    }
    #input_optimizer_div{
        padding:10px;
        background-color: #eff2f6;
        box-shadow: 0 0 8px rgb(165, 165, 165);
    }
    #input_optimizer_title{
        font-size: 14px;
        font-weight: bold;
        color:#393f47;
        margin-bottom:10px;
        text-align: center;
    }
    #input_optimizer_action{
        display:flex;
        flex-direction: column;
        padding-bottom: 6px;
    }
    #input_optimizer_buy{
        display: flex;
        justify-content: center;
        /* background-color: gold; */
    }
    #input_optimizer_buy button, #license_sessions_tvio_info button{
        background:none;
        border:none;
        outline:inherit;
        background-color: green;
        padding:4px 8px;
        border-radius: 8px;
        color:white;
        cursor: pointer;
        font-size: 12px;
        font-weight:bold
    }
    #io_renew button{
        background:none;
        border:none;
        outline:inherit;
        background-color: green;
        padding:4px 8px;
        border-radius: 8px;
        color:white;
        cursor: pointer;
        font-size: 12px;
        font-weight:bold
    }
    #input_optimizer_key_area{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #io_key_area{
        display:flex;
        flex-direction: row;
        align-items: center;
    }
    #io_hidden_toggle{
        color:#393f47;
        padding-left:4px;
        cursor:pointer;
    }
    #io_expiration_area{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #io_expire_date{
        padding:4px 0;
        font-size: 12px;
    }
    /* #io_renew{

    } */
    /* #io_product_key{

    } */
    #io_key{
        display:flex;
        flex-direction: row;
        border:1px solid #aaa;
        padding:4px;
        color:#393f47;
        background-color:white;
        font-size:12px;
        width:200px;
        justify-content: space-between;
        font-family:'Courier New', Courier, monospace;
        font-weight: bold;
    }
    
    .copy_key{
        color:#393f47;
        padding-left: 4px;
        position:relative;
        cursor: pointer;
    }
    /* TODO: @JZ - change #io_hidden_toggle to class properly */
    .copy_key:hover,#io_hidden_toggle:hover,.trash:hover{
        color:#515a65;
    }
    
    .copy_feedback{
        position: absolute;
        top:-24px;
        left: 12px;
        font-size:12px;
        padding:6px;
        border-radius: 12px;
        background-color: rgb(0, 115, 255);
        color:white;
        opacity: 0;
    }
    @keyframes fadeInOut {
        0% {opacity: 0;}
        20% {opacity:1;}
        80% {opacity:1;}
        100% {opacity: 0;}
    }
    .copy_animation{
        animation: fadeInOut 1s ease-in-out 0s 1 normal forwards;
    }
    .hidden{
        display:none !important;
    }
    .bold{
        font-weight:bold;
    }
    .inactive{
        color:red;
        font-weight:bold;
    }
    /* .item{
        display: flex;
        position: relative;
        flex-wrap:wrap;
        align-items: center;
        justify-content: space-evenly;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        width:100%;
    } */
    /* .item:last-child{
        border-bottom: none;
    } */
    
    /* .type_product::before{
        content:"Product";
        display: flex;
        flex-wrap: wrap;
        width: 42%;
        margin: 20px 20px -10px 20px;
        font-weight:bold;
        color:#393f47;
    } */
    /* .type_key::before{
        content:"Key";
        display: flex;
        flex-wrap: wrap;
        width:42%;
        margin: 20px 20px -10px 20px;
        font-weight:bold;
        color:#393f47;
    } */
    
    /* .key::before{
        display: flex;
        flex-wrap: wrap;
        content:"*****-*****-*****-*****-*****";
        align-items: center;
        margin-right:40px;
    } */
    /* .key::after{
        position:absolute;
        top:30%;
        right: 16px;
        align-content: flex-end;
        font-family: "Font Awesome 6 Free";
        font-size:24px;
        padding-left:16px;
        content:"\f06e";
        color:#393f47;
    } */
    /* .key {
        display:flex;
        flex-wrap:wrap;
        position:relative;
        justify-content: flex-end;
        
    } */
    /* #license_key_options{
        display: flex;
        flex-wrap: nowrap;
    } */
    /* .product,.key{
        height: 60px;
        display:flex;
        align-items: center;
        background-color:#fff;
        padding: 0px 20px;
        margin: 20px;
        border: 1px solid #ddd;
        box-shadow: 0px 0px 8px #ddd;
        color: #393f47;
        min-width: 220px;
        min-height:60px;
    } */
    
    /* #items_container{
        display:flex;
        flex-wrap: wrap;
        max-width:360px;
        justify-content: flex-start;
        box-shadow: 0px 0px 8px #ccc;
        margin:10px 10px 50px 10px;
        background-color: #eff2f6;
    } */
    /* .item_desc{
        width:100%;
        background-color: rgb(81, 168, 255);
        border-radius:16px;
        display: flex;
        justify-content: center;
        margin-top:10px;
        padding: 8px 0px;
        font-weight: bold;
        text-shadow: 0px 0px 4px darkblue;
        text-align: center;
    } */
    /* #item_license_keys,#item_security,#item_account,#item_products,#item_discord{
        width:130px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin:10px;
        border-radius: 16px;
        padding: 10px;
        color:#fff
    } */
    
    /* .icon{
    display:flex;
    justify-content: center;
    align-items: center;
    width:32px;
    height:32px;
    margin-right:5px;
    font-family: "Font Awesome 6 Free";
    font-size: 24px;
    line-height: 16px;
    font-weight: 900;
    border-radius: 50px;
    color:#393f47;
} */

    /* #license_key_options{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    } */
    /* #license_key_menu ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    } */
    /* #license_key_menu li{
        display:flex;
        align-items: center;
        height:40px;
        width: 100%;
        padding-left: 10px;
    } */
    /* #license_key_menu{
        width:100%;
    } */
    /* #license_key_menu li:nth-child(odd){
        background-color: rgb(81, 168, 255);
    } */
    /* #license_key_menu li:nth-child(even){
        background-color: rgb(16, 110, 218);
    } */
    /* #license_key_menu a{
        color:#fff;
        text-decoration: none;
    } */
    /* #license_key_container a::hover{
        text-decoration: underline;
    } */
    /* #license_key_wrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height:100%;
        align-items: flex-start;
        justify-content: center;
    } */
    /* input{
    width:100%;
    height:40px;
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    padding-left:10px;
    font-size:16px;
    align-items: center;
    color:#333;
    } */
    /* .bold{
        font-weight:bold;
    } */
    
    /* h1 {
        font-size:150%;
        height:40px;
        line-height: 40px;
    } */
    /* h2 {
        height:40px;
        line-height: 40px;
    } */

    /* a {
        color: rgb(95, 167, 240);
        text-decoration: none;
    } */
    /* a:visited{
        color:rgb(95 167 240)
    } */
    @media all and (min-width: 320px){
        #input_optimizer_div{
            max-width:300px;
        }
        .license_session_name{
            max-width: 192px;
        }
        /* .type_product,.type_key{
            min-width: 335px;
            max-width: 360px;
        } */
        /* .product,.key{
            min-width:300px;
        } */
    }
    /* Tablet menu */
    @media all and (min-width: 700px){
        #input_optimizer_div{
            max-width:400px;
        }
        .license_session_name{
            max-width: 260px;
        }
        #license_key_wrapper{
            align-items:center;
        }
        
        #license_key_container{
            margin-left:180px;
        }
    }
    @media all and (min-width: 960px){
        /* #license_key_title_area{
            text-align: left;
            justify-content: left;
        } */
        /* .product_key_link_icon{
            padding-top:20px;
            font-family: 'Font Awesome 6 Free';
            width: 24px;
            text-align: center;
            font-size:18px;
            color: #393f47;
        } */
        /* .product_key_link_icon::after{
            content: '\f0c1';
            font-weight:bold;
        } */
        /* #items_container{
            max-width:720px;
        } */
        /* .item{
            display: flex;
            justify-content: space-evenly;
        } */
    }
</style>