<template>
  <div id="about_us_wrapper">
      <div id="about_us_container">
          <div id="about_us">
            <h1 class="bold title">About Us</h1>
            <h1 class="bold">We are not a huge company.</h1>
            <h2 class="desc">We are two friends who have developed this software for our own needs. In doing so, we realized the power of sharing this software with others to develop a community of like-minded people who share our interests in trading & chart analysis.</h2>
            <h1 class="bold">Contact Us On Discord</h1>
            <a href="https://discord.gg/pGHHRczpbu">
              <button id="join_discord"><font-awesome-icon icon="fa-brands fa-discord" />Join Us On Discord</button>
            </a>
            <h2>We have a Discord server where our community members can come to share ideas and get support to make their strategies more successful. Come say hi to us, we don't bite.</h2>
            <div id="about_us_names">
              <div id="gentleman_goat">
                <div id="discord_avatar_goat"></div>
                <h3 class="discord_owner">@Gentleman_Goat</h3>
                <h3>Joe (Main Developer)</h3>
              </div>
              <div id="i3Lux">
                <div id="discord_avatar_i3Lux"></div>
                <h3 class="discord_admin">@i3Lux</h3>
                <h3>Ben (Co-Owner)</h3>
              </div>
            </div>
            <div id="email_support">
              <h3><font-awesome-icon icon="fa-solid fa-envelope" />Email Support:</h3>
              <h3><a href="#">support@tradingtools.software</a></h3>
            </div>
          </div>
      </div>
  </div> 
</template>

<script>
</script>
<style scoped>
.title{
  font-size:200%;
}
#join_discord{
    cursor:pointer;
}
#join_discord:hover{
    background-color: #5b6dae;
}
.checkbox{
    width:20px;
    height:20px;
    display: inline;
}
#about_us{
  display:flex;
  flex-wrap: wrap;
  padding-top:30px;
  padding-bottom:30px;
  justify-content: center;
  margin:0px 20px;
}

#email_support{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width:100%;
  /* background-color: green; */
  font-size:12px;
  font-weight:bold;
  margin-top:20px;
  padding: 0;
}
#about_us_names{
  display:flex;
  width:100%;
}

#about_us_names h3,#email_support h3{
  font-weight:bold;
}
.discord_owner{
  color: gold;
  text-shadow: 1px 1px 0px darkgoldenrod;
  /* color: darkgoldenrod;
  text-shadow: 1px 1px 0px gold; */
}
.discord_admin{
  color: rgb(122, 25, 250);
  text-shadow: 1px 1px 0px rgb(206 168 255);
  /* color: rgb(206, 168, 255);
  text-shadow: 1px 1px 0px rgb(122 25 250); */
}

#discord_avatar_goat{
  background-image: url(../assets/discord_avatar_goat.png);
}
#discord_avatar_i3Lux{
  background-image: url(../assets/discord_avatar_i3Lux.png);
}
#discord_avatar_goat, #discord_avatar_i3Lux{
  background-size: contain;
  background-repeat: no-repeat;
  background-color:#90a4eb;
  height:100px;
  width:100px;
  border-radius: 50px;
  /* border: 10px solid #5e7ce9; */
  border: 10px solid #7289da;
  margin-bottom:5px;
  box-shadow: 0px 0px 10px #90a4eb;
}
#gentleman_goat,#i3Lux{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size:13px;
  font-weight:bold;
  width:50%;
  /* background-color: green; */
}

#about_us_container{
  max-width: 540px;
  /* height: 80%; */
  margin: 20px;
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content:space-around;
  background-color: #eff2f6;
  box-shadow: 0px 0px 12px #999;
  /* background-color: #96c2ed; */
  /* background-color: #69a8e7;
  box-shadow: 0px 0px 10px #96c2ed;
  color:#eee;
  text-shadow: 1px 1px 2px #777; */
}

#about_us_container a{
  /* color:#eee;
  text-shadow: 1px 1px 2px #777;*/
  text-decoration: underline; 
}
#about_us_container a::hover{
  text-decoration: dashed;
}

#about_us_wrapper{
  background-color: #cecee0;
  /* background-color: dodgerblue; */
  /* background-color: #f3f2ef; */
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height:100%;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}
.bold{
  font-weight:bold;
}

h1 {
  font-size:150%;
  width:100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

h2 {
  width:100%;
  padding: 20px 0px;
}

h3{
  width:100%;
  margin-top:2px;
}

button {
  width: 100%;
  height: 40px;
  /* background-color:rgb(121, 188, 255); */
  background-color: #7289da;
  border: 1px solid #667ac5;
  font-weight:bold;
  color: #FFF;
  border-radius: 4px;
  max-width: 480px;
  text-shadow: 1px 1px 1px #435081;
  /* box-shadow: 0px 0px 10px rgba(75, 0, 255, 0.5); */
  box-shadow: 0px 0px 10px #90a4eb;
}

#join_discord{
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:16px;
}
#join_discord svg{
  padding-right:5px;
  -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .4));
  filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .4));
}

a {
    color: rgb(95, 167, 240);
    text-decoration: none;
    
}
a:visited{
    color:rgb(95 167 240);
}

 /* Tablet menu */
@media all and (min-width: 700px){

}
</style>