import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue"; //@ is directive refering to /src folder.
import NotFound from "@/views/NotFound.vue";
import AboutUs from "@/views/AboutUs.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/SignUp.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Dashboard from "@/views/Dashboard.vue";
import LicenseKeys from "@/views/LicenseKeys.vue";
import Security from "@/views/Security.vue";
import Profile from "@/views/Profile.vue";
import Products from "@/views/Products.vue";
import TOS from "@/views/TOS.vue";
import PP from "@/views/PrivacyPolicy.vue";

import store from '@/store';

const routes = [
  {
    path: "/",
    alias: "/optimizer",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs
  },
  {
    path: "/terms-of-service",
    name: "Terms Of Service",
    component: TOS
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PP
  },
  {
    path: "/login",
    alias: "/log-in",
    name: "Login",
    component: Login
  },
  {
    path: "/signup",
    alias: "/sign-up",
    name: "Signup",
    component: Signup
  },
  // {
  //   path: "/confirmemail",
  //   alias: "/confirm-email",
  //   name: "ConfirmEmail",
  //   component: ConfirmEmail,
  //   // children: [
  //   //   {
  //   //     name:"ConfirmEmailAuto",
  //   //     path: ":hash",
  //   //     component: ConfirmEmail,
  //   //     props: true
  //   //   }
  //   // ]
  // },
  {
    path: "/confirmemail/:email_hash",
    alias: "/confirm-email/:email_hash",
    name: "ConfirmEmail",
    component: ConfirmEmail,
    props:true
  },
  {
    path: "/confirmemail/:email_hash/:hash",
    alias: "/confirm-email/:email_hash/:hash",
    name: "ConfirmEmailAuto",
    component: ConfirmEmail,
    props:true
  },
  {
    path: "/resetpassword/:reset_password_hash",
    alias: "/reset-password/:reset_password_hash",
    name: "ResetPassword",
    component: ResetPassword,
    props:true
  },
  {
    path: "/forgotpassword",
    alias: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/dashboard",
    alias: "/dash-board",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/licensekeys",
    alias: "/license-keys",
    name: "LicenseKeys",
    component: LicenseKeys
  },
  {
    path: "/security",
    alias: "/account-security",
    name: "Security",
    component: Security
  },
  {
    path: "/profile",
    alias: "/account",
    name: "Profile",
    component: Profile
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(`Router beforeEach -> to:${to.name} from:${from.name}`);
  if(store.state.loggedIn==true){
    console.log("Route LoggedIn IS Detected");
    switch(to.name){
      case "Home":
        //router.push({name:"Dashboard"});
        return;
    }
  }else{
    console.log("Route LoggedIn NOT detected")
  }
  //console.log(to);
  store.commit('SET_ROUTE', to);
  next();
});

export default router;