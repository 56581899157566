<template>
    <Security></Security>
    <router-view />
</template>

<script>
    import Security from '@/components/security.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-security',
        components: {
            Security
        },
        mounted(){
            this.setSidebarHidden(false);
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>