<template>
    <div id="products_wrapper">
        <div id="products_container">            
            <div id="products_main">
                <div id="products_title_area">
                    <h1 class="bold">Products</h1>
                </div>
                <div id="items_container">
                    <div id="product_tvio">
                        <a href="/">
                            <div class="tvio icon"></div>
                            <div class="item_desc">Tradingview Input Optimizer v{{ version }}</div>
                        </a>
                    </div>
                    <div id="product_autobot">
                        <a href="/">
                            <div class="autobot icon"></div>
                            <div class="item_desc">Auto Trading Bot v{{ version }}</div>
                        </a>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</template>
    
<script>
    export default{
        props: {
            version: String,
            user: String,
            email: String,
            accountID: String
        }
    }
</script>
<style scoped>

#products_title_area{
    display: flex;
    align-items: center;
    text-align: center;
    width:100%;
    margin: 20px 0px;
}
#items_container{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0px 0px 8px #ccc;
    /* border-radius: 24px; */
    margin:10px;
}
.item_desc{
    width:100%;
    /* background-color: rgb(81, 168, 255); */
    border-radius:16px;
    display: flex;
    justify-content: center;
    margin-top:10px;
    padding: 8px 0px;
    font-weight: bold;
    color:#138aff;
    text-shadow: 1px 1px 1px rgb(183, 183, 240);
    text-align: center;
}
#product_tvio,#product_autobot{
    /* background-color: red; */
    width:260px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:10px;
    /* border: 2px dashed rgb(50, 141, 215); */
    /* border-radius: 16px; */
    /* background-color: rgb(203, 224, 255); */
    padding: 10px;
    color:#fff
    
}

.icon{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100px;
    height:100px;
    background-color: rgb(81, 168, 255); 
    font-family: "Font Awesome 6 Free";
    font-size: 60px;
    line-height: 16px;
    font-weight: 900;
    border-radius: 50px;
    border: 1px solid dodgerblue;
    color: rgb(169,209,255);
    box-shadow: 0px 0px 16px rgb(81, 168, 255);
    -webkit-text-stroke: 1px dodgerblue;
    text-shadow: 0px 0px 2px darkblue;
}
.icon_brands{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100px;
    height:100px;
    background-color: rgb(81, 168, 255); 
    font-family: "Font Awesome 6 Brands";
    font-size: 60px;
    line-height: 16px;
    font-weight: 900;
    border-radius: 50px;
    border: 1px solid dodgerblue;
    color: rgb(169,209,255);
    box-shadow: 0px 0px 16px rgb(81, 168, 255);
    -webkit-text-stroke: 1px dodgerblue;
    text-shadow: 0px 0px 2px darkblue;
}
.tvio::after{
    content: "\f013";
}

.autobot::after{
    content: "\f544";
}

.selected{
    font-weight:bold;
}
#products_container{
    /* padding-top:62px; */
    width: 100%;
    height: 100%;
    /* background-color:red; */
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content:space-around;
    justify-content: center;
    /* margin: 20px; */
    /* background-color: #96c2ed; */
    /* background-color: #69a8e7; */
    /* box-shadow: 0px 0px 10px #96c2ed; */
    /* color:#eee; */
    /* text-shadow: 1px 1px 2px #777; */
}

#products_container a::hover{
    text-decoration: underline;
}
/* fineprint{
    text-shadow: 1px 1px 1px #666;
} */
#products_wrapper{
    /* background-color: dodgerblue; */
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height:100%;
    align-items: flex-start;
    justify-content: center;
}
.bold{
    font-weight:bold;
}
h1 {
    /* background-color:green; */
    font-size:150%;
    width:100%;
    height:40px;
    line-height: 40px;
    /* padding:20px; */
}
a {
    color: rgb(95, 167, 240);
    text-decoration: none;
}
a:visited{
    color:rgb(95 167 240)
}
@media all and (min-width: 470px){
    #items_container{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-shadow: 0px 0px 8px #ccc;
        /* border-radius: 24px; */
        margin:10px;
    }
}
/* @media all and (min-width:510px){
    #wrapper{
        display:flex;
        flex-wrap: nowrap;
    }
    #dashboard_wrapper{
        padding-left: 180px;
    }
} */
    /* Tablet & Desktop */
@media all and (min-width: 700px){
    #products_wrapper{
        align-items:center;
        padding-left:0;
    }
    #products_container{
        margin-left:180px;
    }
    #products_container{
        display:flex;
        width:700px;
        justify-content: center;
        /* padding-top:62px; */
        flex-wrap: wrap;
        flex-direction: row;
        align-content:space-around;
    }
    #items_container{
        margin-right:18px;
    }
}
/* @media all and (min-width: 960px){
     
} */
</style>