<template>
    <div id="forgot_pw_wrapper">
        <div id="forgot_pw_container">
            <div id="title" class="bold">Forgot Password</div>        
            <div id="subtitle">Please enter the email address associated with your account. If it is found in our system, you will be sent an email with instructions to change your password:</div>
            <div id="email" class="bold">Email</div>
            <input type="text" v-model="email"/>
            <button id="submit_button" @click="sendEmail">Send Email</button>
            <div id="feedback">
                <div class="error error_se" :class="{error_active_se:se_err}">{{ se_err_msg }}</div>
                <div class="success success_se" :class="{success_active_se:se_success}">{{ se_success_msg }}</div>
            </div>
            <div id="back_to_login">
                <router-link to="/login">Back to Login</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    methods:{
        sendEmail: async function (){
            this.se_err = false;
            this.se_success = false;
            var json_to_send = "";
            json_to_send = {
                "details": {
                    "email": this.email
                }
            };
            //const url = "https://localbeta.tradingtools.software/api/security/forgot-password";
            const url = `https://${document.location.hostname}/api/security/forgot-password`;
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(json_to_send)
            };
            try {
                if(this.email.length < 1){
                    this.se_err = true;
                    this.se_err_msg = "Email field cannot be empty";
                    return;
                }

                var response = await fetch(url, options);
                const json_response = await response.json();
                console.log("Showing json response below");
                console.log(json_response);
                if (response.ok) {
                    console.log("Response OK");
                    this.se_success = true;
                    this.email = "";
                    setTimeout(() => {
                        this.se_success = false;
                    },1000);
                }else{
                    this.se_err = true;
                    switch (json_response.code) {
                        case -1:
                            this.se_err = true;
                            this.se_err_msg = "An unknown error occured."
                            break;
                        case 14:
                            this.se_err = true;
                            this.se_err_msg = "Request too soon. Please wait until 15 minutes elapse from previous request.";
                            break;
                        case 18:
                            this.se_err = true;
                            this.se_err_msg = "Account not found.";
                            break;
                    }
                }
            } catch (error) {
                console.error(error);
                this.se_err = true;
                this.se_err_msg = "Error connecting to server.";
            }
        }
    },
    data: () => ({
        email: "",
        se_err: false,
        se_err_msg:"Error!",
        se_success: false,
        se_success_msg: "Success!",
    }),
}
</script>

<style scoped>
#feedback{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.error,.success{
    color: white;
    display:none;
    /* width:100%; */
    margin-top:5px;
    margin-bottom:15px;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    border-radius: 20px;
    padding: 8px;
    text-align: center;
}
.success{
        background-color: #217e15;
    }
    
    .error{
        background-color: #e10000;
    }
.error_active_se,.success_active_se{
    display: flex !important;
}

#back_to_login{
    display: flex;
    width:100%;
    font-size:12px;
    justify-content: center;
    align-items: center;
}
#back_to_login a {
    text-decoration: none;
}

#forgot_pw_wrapper{
    display: flex;
    flex-direction: column;
    flex-grow:1;
    background-color: #cecee0;
    align-items:center;
   
}
#forgot_pw_container{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content:space-around;
    margin:10px;
    padding:20px;
    box-shadow: 0 0 8px rgb(165,165,165);
    background-color: #eff2f6;
    max-width: 700px;
}

#email{
    margin-top:10px;
    margin-bottom:4px;
}

#title{
    font-size:24px;
    width:100%;
    text-align: center;
}
#subtitle{
    margin-top:10px;
    font-size:14px;
    text-align: center;
}
#email{
    margin-top:10px;
    margin-bottom:4px;
}
input{
    width:100%;
    height:40px;
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    font-size:16px;
    align-items: center;
    color:#333;
    padding-left: 4px;
}
.bold{
    font-weight:bold;
}
#submit_button{
    cursor: pointer;
}
#submit_button:hover{
    background-color: rgb(63, 158, 253);
}
button {
    width: 100%;
    height: 40px;
    background-color:rgb(121, 188, 255);
    border: 1px solid rgb(95,167,240);
    font-weight:bold;
    color: #FFF;
    margin: 20px 0px;
    border-radius: 4px;
}

a {
    color: rgb(95, 167, 240);
    text-decoration: none;
    
}
a:visited{
    color:rgb(95 167 240)
}
.checkbox{
    width:20px;
    height:20px;
    display: inline;
}
 /* Tablet menu */
@media all and (min-width: 700px){

}
@media all and (min-width:960px){
    
}
</style>