<template>
    <div id="confirm_email_wrapper">
        <div id="confirm_email_container">
            <div id="title" class="bold">Confirm Your Account</div>        
            <div id="subtitle">A verification email has been sent to your email address. Either click the link in that email or enter in the code that was sent to you below:</div>
            <div id="enter_code" class="bold">Enter Code From Email</div>
            <input type="text" v-model="conf"/>
            <div class="error error_conf" :class="{error_active_conf:conf_err}">{{ conf_err_msg }}</div>
            <div class="success success_conf" :class="{success_active_conf:conf_success}">{{ conf_success_msg }}</div>
            <div id="resend" class="bold">Having trouble with your code?</div>
            <a id="resend_email" href="#" @click="resend_email">Resend Email</a>
            <button id="submit_button" @click="verify_email">Continue</button>
        </div>
    </div>    
</template>

<script>
    import router from '@/router';
    export default {
    props: {
        hash:{
            type: String,
            required: false
        },
        email_hash:{
            type: String,
            required: true
        }
    },
    data: () => ({
        conf:'', conf_err:false, conf_err_msg:'',
        conf_success:false,conf_success_msg:'Email address has been verified!',
    }),
    methods: {
        async resend_email(){
            this.conf_err = false;
            var json_to_send='';
            json_to_send = {
                "details":{
                    "email_hash":this.email_hash
                }
            };
            const url = `https://${document.location.hostname}/api/verify/set-codes-and-send-email`;
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(json_to_send)
            };
            try{
                var response = await fetch(url,options);
                
                const json_response = await response.json();
                console.log("Showing json response below");
                console.log(json_response);
                if(response.ok){
                    console.log("Sent succesfully, TODO show message dialog");
                }else{
                    switch (json_response.code) {
                        case 14://Can not send until 15 minutes has gone by.
                            this.conf_err = true;
                            this.conf_err_msg = "You can not send another confirmation email until 15 minutes has elapsed.";
                            break;
                        case 11:
                            this.conf_err = true;
                            this.conf_err_msg = "Email has already been verified.";                   
                            break;
                        case 2://email not found
                            this.conf_err = true;
                            this.conf_err_msg = "Email not found."
                            break;
                    }
                }
            }catch(error){
                this.conf_err = true;
                this.conf_err_msg = "Too many attempts.  Please try again later.";
            }
        },
        async verify_email(){
            var json_to_send='';
            if(typeof this.hash != 'undefined'){
                json_to_send = {
                    "details":{
                        "email_hash":this.email_hash,
                        "hash":this.hash
                    }
                };
            }else{
                json_to_send = {
                    "details":{
                        "email_hash":this.email_hash,
                        "code":this.conf
                    }
                };
            }
            this.conf_err = false;
            const url = `https://${document.location.hostname}/api/verify/email`;
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(json_to_send)
            };
            try{
                var response = await fetch(url,options);
                const json_response = await response.json();
                console.log("Showing json response below");
                console.log(json_response);
                if(response.ok){
                    this.conf_success = true;
                    setTimeout(()=>{
                        router.push({name:'Login'});
                    },2000);
                }else{
                    switch (json_response.code) {
                        case 2://Email not found
                            this.conf_err = true;
                            this.conf_err_msg = "Email address not found.";
                            break;
                        case 11:
                            this.conf_err = true;
                            this.conf_err_msg = "Email has already been verified.";                   
                            break;
                        case 12://email malformed
                            this.conf_err = true;
                            this.conf_err_msg = "Code does not match what was sent to your email.  Failure to enter the code correctly 3 times results in a temporary ban."
                            break;
                        case 13://email time elapsed
                            this.conf_err = true;
                            if(window.innerWidth < 700){
                                this.conf_err_msg = "You must enter the code within 15 minutes.  Please press the 'Tap Here To Resend' link to try again."
                            }else{
                                this.conf_err_msg = "You must enter the code within 15 minutes.  Please press the 'Click Here To Resend' link to try again."
                            }
                            break;
                    }
                }
            }catch(error){
                this.conf_err = true;
                this.conf_err_msg = "Too many attempts.  Please try again later.";
            }
        }
    },
    mounted(){
        console.log("Mounting");
        console.log('hash below...');
        console.log(this.hash);
        console.log('email_hash below');
        console.log(this.email_hash);
        if(typeof this.hash != 'undefined'){
            this.verify_email();//@JA - If this is present we automatically verify if possible and go to the login page.
        }
    }
}
</script>
<style scoped>
#title{
    margin-top:10px;
    font-size:24px;
    width:100%;
    text-align: center;
}
#subtitle{
    margin-top:10px;
    font-size: 16px;
    text-align: center;
}
#enter_code{
    margin-top:10px;
    margin-bottom:4px;
}
#wrapper{
    display:flex;
    flex-direction: column;
    height:calc(100% - var(--nav-height));
    width:100%;
    background-color: #cecee0;
    }
.error{
    color: white;
    display:none;
    width:100%;
    margin-top:5px;
    margin-bottom:5px;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    background-color: #e10000;
    border-radius: 40px;
    padding: 10px 20px;
    /* white-space: nowrap;
    overflow:hidden; */
}
.success{
    color: white;
    display:none;
    width:100%;
    margin-top:5px;
    margin-bottom:5px;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    background-color: #217e15;
    border-radius: 40px;
    padding: 10px 20px;
}
.error_active_conf,.success_active_conf{
    display: flex !important;
}
#confirm_email_wrapper{
    background-color: #cecee0;
    display: flex;
    flex-grow:1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
}
#confirm_email_container{
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content:flex-start;
    background-color: #eff2f6;
    padding:10px;
    margin:10px;
    box-shadow: 0px 0px 8px rgb(165,165,165);
}

#resend{
    display:flex;
    width:100%;
    font-size:11px;
    margin:10px 0px;
}
#resend_email{
    font-size:11px;
}

input{
    width:100%;
    height:40px;
    border: 2px solid rgb(95,167,240);
    box-shadow: 0px 0px 5px rgb(95 167 240);
    border-radius: 4px;
    font-size:16px;
    align-items: center;
    color:#333;
    padding:10px;
}
.bold{
    font-weight:bold;
}

button {
    width: 100%;
    height: 40px;
    background-color:rgb(121, 188, 255);
    border: 1px solid rgb(95,167,240);
    font-weight:bold;
    color: #FFF;
    margin: 20px 0px;
    border-radius: 4px;
    cursor:pointer;
}
button:hover{
    background-color: rgb(63, 158, 253);
}

a{
    color: rgb(95, 167, 240);
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}
a:visited{
    color:rgb(95 167 240)
}

.checkbox{
    width:20px;
    height:20px;
    display: inline;
}
 /* Tablet menu */
@media all and (min-width: 700px){
    #confirm_email_container{
        max-width:700px;
    }
    
}
/* @media all and (min-width:960px){
   
} */
</style>