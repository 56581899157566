<template>
    <footer id="footerComponent">
        Copyright &copy; {{ currentYear }} TradingTools.Software
    </footer>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
footer{
    line-height:28px;
    text-align:center;
    color: white;
    font-size: 12px;
    background-color: #222;
    width:100%;
    height:30px;
    position:relative;
    bottom:0;
    z-index:10;/* This will show above the dashboard menu */
}
</style>