<template>
    <ForgotPassword></ForgotPassword>
    <router-view />
</template>

<script>
    import ForgotPassword from '@/components/forgotpassword.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-forgot-password',
        components: {
            ForgotPassword
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>