<template>
    <AboutUs></AboutUs>
    <router-view />
</template>

<script>
    import AboutUs from '@/components/aboutus.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-about-us',
        components: {
            AboutUs
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>