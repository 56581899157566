<template>
    <div id="reset_password_wrapper">
        <div id="reset_password_container">            
            <div id="reset_password_main">
                <div id="reset_password_title_area">
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-id-card" /></div>
                    <div class="title">Reset Password</div>
                </div>
                <div id="reset_password_div">
                    <div id="reset_password_input_area">
                        <div id="new_password_title">New Password:</div>
                        <input type="password" v-model="new_password"/>
                        <div class="error error_np" :class="{error_active_np:new_password_err}">{{ new_password_err_msg }}</div>
                        <div id="confirm_password_title">Confirm Password:</div>
                        <input type="password" v-model="confirm_password"/>
                        <div class="error error_cp" :class="{error_active_cp:confirm_password_err}">{{ confirm_password_err_msg }}</div>
                        <div class="success success_cp" :class="{success_active_pw:pw_change_success}">{{ pw_change_success_msg }}</div>
                        <div id="button_area">
                            <button @click="saveNewPassword">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import router from '@/router';
    export default {
        methods:{
            saveNewPassword: async function(){
                this.new_password_err = false;
                this.confirm_password_err = false;
                var json_to_send = "";
                json_to_send = {
                    "details": {
                        "new_password": this.new_password,
                        "hash":this.reset_password_hash
                    }
                };
                //const url = "https://localbeta.tradingtools.software/api/security/reset-password";
                const url = `https://${document.location.hostname}/api/security/reset-password`;
                const options = {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify(json_to_send)
                };
                try {
                    var upper_case_regex = /(?=.*[A-Z])/;
                    var lower_case_regex = /(?=.*[a-z])/;
                    var digit_regex = /(?=.*[0-9])/;
                    var special_character_regex = /([^A-Za-z0-9])/;

                    if(this.new_password.length < 8){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must be at least 8 characters";
                        return;
                    }
                    if(this.new_password.length > 32){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must be less than 32 characters";
                        return;
                    }
                    if (!upper_case_regex.test(this.new_password)){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must have at least one upper case letter.";
                        return;
                    }
                    if (!lower_case_regex.test(this.new_password)){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must have at least one lower case letter.";
                        return;
                    } 
                    if (!digit_regex.test(this.new_password)){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must have at least one number.";
                        return;
                    }
                    if(!special_character_regex.test(this.new_password)){
                        this.new_password_err = true;
                        this.new_password_err_msg = "Password must have at least one special character - !@#$%^&*";
                        return;
                    }
                    if(this.confirm_password != this.new_password){
                        this.confirm_password_err = true;
                        this.confirm_password_err_msg = "Passwords do not match.";
                        return;
                    }
                    var response = await fetch(url, options);
                    const json_response = await response.json();
                    console.log("Showing json response below");
                    console.log(json_response);
                    if (response.ok) {
                        console.log("OK for now");
                        this.pw_change_success = true;
                        this.new_password = "";
                        this.confirm_password = "";
                        setTimeout(() => {
                            this.pw_change_success = false;
                            router.push({name:'Login'});
                        },1000);
                    }else{
                        console.log("NOT OK for now")
                        switch (json_response.code) {
                            case 4:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Too Short (min: 8 characters)";
                                break;
                            case 5:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Too Long (max: 32 characters)";
                                break;
                            case 6:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Must have at least 1 uppercase letter";
                                break;
                            case 7:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Must have at least 1 lowercase letter";
                                break;
                            case 8:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Must have at least 1 number";
                                break;
                            case 9:
                                this.new_password_err = true;
                                this.new_password_err_msg = "Must have at least 1 special character (!@#$%^&*)";
                                break;
                            case 17:
                                this.confirm_password_err = true;
                                this.confirm_password_err_msg = "Session Expired. Please login again";
                                break;
                        }
                    }
                }catch(error){
                    console.error(error);
                    this.confirm_password_err = true;
                    this.confirm_password_err_msg = "Error connecting to server";
                }
            },
        },
        props: {
            reset_password_hash:{
                type: String,
                required: true
            }
        },
        data: () => ({
            pw_change_success: false,
            pw_change_success_msg:"Success!",
            new_password: "",
            confirm_password: "",
            new_password_err: false,
            new_password_err_msg: "Error with New Password",
            confirm_password_err: false,
            confirm_password_err_msg: "Passwords must match",
        }),
    }
</script>
<style scoped>
    @keyframes fadeInOut {
        0% {opacity: 0;}
        20% {opacity:1;}
        80% {opacity:1;}
        100% {opacity: 0;}
    }
    .success{
        color: white;
        display:none;
        width:100%;
        margin-top:5px;
        margin-bottom:5px;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        background-color: #217e15;
        border-radius: 40px;
        padding: 6px 10px;
    }
    .error{
        color: white;
        display:none;
        width:100%;
        margin-top:5px;
        margin-bottom:5px;
        justify-content: center;
        font-weight: bold;
        font-size: 10px;
        background-color: #e10000;
        border-radius: 20px;
        padding: 4px;
        text-align: center;
    }
    #new_password_title,#confirm_password_title{
        font-size:14px;
        font-weight: bold;
        color: #393f47;
    }
    .error_active_np,.error_active_cp,.success_active_pw{
        display:flex !important;
    }
    .success_active_pw{
        animation: fadeInOut 1s ease-in-out 0s 1 normal forwards;
    }
    #reset_password_wrapper{
        display:flex;
        flex-grow: 1;
    }
    #reset_password_container{        
        background-color: #cecee0;
        width: 100%;
        height: 100%;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:flex-start;
        justify-content: center;
    }
    #reset_password_main{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #reset_password_title_area{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 10px 0;
        color:#393f47;
        width:100%;
        max-width: 720px;
    }
    #reset_password_div{
        display: flex;
        flex-direction: column;
        width:260px;
        padding:10px;
        background-color: #eff2f6;
        box-shadow: 0 0 8px rgb(165, 165, 165);
    }
    #reset_password_div input{
        width:100%;
        padding:0 4px;
    }
    #new_password_title,#confirm_password_title,#button_area{
        margin-top:10px;
    }
    #button_area{
        display:flex;
        justify-content: center;
    }
    button{
        padding:2px;
    }
    .icon{
        font-size:24px;
        padding-right:5px;
    }
    .title{
        font-size:24px;
        font-weight: bold;
    }
    /* Tablet menu */
    @media all and (min-width: 700px){
        #reset_password_wrapper{
            align-items:center;
        }
    }
</style>