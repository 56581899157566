<template>
    <div id="sidebar_menu" :class="{hidden:sidebarHidden}">
        <ul id="sidebar_options">
            <!--TODO @JZ Finish adding in the icons properly to sidebar-->
            <li id="li_dashboard" class="menu_item li_dashboard" :class="{selected:route.name=='Dashboard'}"><router-link to="/dashboard"><font-awesome-icon icon="fa-solid fa-gauge-high" />Dashboard</router-link></li>
            <li id="li_license_keys" class="menu_item li_license_keys" :class="{selected:route.name=='LicenseKeys'}"><router-link to="/license-keys"><font-awesome-icon icon="fa-solid fa-key" />License Keys</router-link></li>
            <li id="li_profile" class="menu_item li_profile" :class="{selected:route.name=='Profile'}"><router-link to="/profile"><font-awesome-icon icon="fa-solid fa-user" />Profile</router-link></li>
            <li id="li_security" class="menu_item li_security" :class="{selected:route.name=='Security'}"><router-link to="/security"><font-awesome-icon icon="fa-solid fa-id-card" />Security</router-link></li>
            <!-- <li id="li_account" class="menu_item li_account" :class="{selected:route.name=='Account'}"><router-link to="/account"><font-awesome-icon icon="fa-solid fa-user" />Account</router-link></li> -->
            <!-- <li id="li_products" class="menu_item has_submenu li_products" @click="toggle_submenu_visibility" :class="{submenu_padding_adjust:product_menu_visible,selected:route.name=='Products'}"><router-link to="/products"><font-awesome-icon icon="fa-solid fa-box-open" />Products</router-link>
                <ul class="submenu ul_products" :class="{hidden:product_menu_visible}">
                    <li class="submenu_item li_products_tvio">TradingView Input Optimizer v {{ version }}</li>
                    <li class="submenu_item li_products_autobot">Auto Trading Bot v {{ version }}</li>
                </ul>
            </li> -->
            <li id="li_billing" class="menu_item li_billing" :class="{disabled_menu_item:billing_disabled}"><a :href="billingurl" target="_blank"><font-awesome-icon icon="fa-solid fa-file-invoice-dollar" />Billing & Invoices</a></li>
            <li id="li_discord" class="menu_item li_discord"><a href="https://discord.gg/pGHHRczpbu"><font-awesome-icon icon="fa-brands fa-discord" />Discord</a></li>
            <li id="li_download" class="menu_item li_download"><a :href="`/downloads/tradingviewinputoptimizer_v${version}.zip`"><font-awesome-icon icon="fa-solid fa-file-arrow-down" />Download Now</a></li>
        </ul>
    </div>
    <!-- <div id="sidebar_info" :class="{hidden:sidebarHidden}">
        <div id="sidebar_avatar"></div>
        <div id="sidebar_greeting" class="bold">Welcome, {{ user }}!</div>
        <div id="sidebar_email">{{ email }}</div>
        <div id="sidebar_accountID">AccountID:{{ accountID }}</div>
    </div> -->
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['sidebarHidden','route','loggedInEmail','licenses']),
        billingurl: function(){
            // return process.env.VUE_APP_BILLING_URL+'?prefilled_email='+this.loggedInEmail
            return "/stripe/billing";
        }
    },
    data: () => ({
        billing_disabled:false,
        product_menu_visible:true,
        license_info:{subscriptions:[{"current_end_period_timestamp":"123456789"}]}
    }),
    methods: {
        toggle_submenu_visibility(){
            this.product_menu_visible = !this.product_menu_visible;
        },
        handleUpdates: function(){
            console.log("Printing Licenses from dashboard.vue...");
            console.log(this.licenses);
            var filteredLicenses = this.licenses.filter(function (el){
                return el.product_code == '1' || el.product_code == '3' || el.product_code == '4'
            });
            try {
                var filteredLicense = filteredLicenses[0];
                this.license_info = filteredLicense;
                if(this.license_info.subscriptions[0].stripe_subscriptionID == null){
                    this.billing_disabled = true;
                }else{
                    this.billing_disabled = false;
                }
            }catch(error){
                this.billing_disabled = true;
            }
        }
    },
    watch: {
        deep: true,
        licenses: {
            handler(newValue, oldValue) {
                console.log("License vuex change detected");
                this.handleUpdates(newValue,oldValue);
            },
        },
    },
    mounted() {
        console.log("mounted sidebar event");
        this.handleUpdates();
    },      
    name: 'Sidebar-Main',
    props: {
        version: String,
    },
}
</script>
<style scoped>
    .disabled_menu_item a{
        color: rgb(117, 117, 117) !important;
        pointer-events: none;
    }
    #sidebar_email{
        /* display:block !important; */
        width:100%;
        /* overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction:rtl; */
    }
    .hidden{
        display:none !important;
    }
    #sidebar_menu{
        position:relative;
        box-shadow: 1px 0px 4px #ccc;
        background-color: rgb(31,35,39);
        padding: 10px 0px;
        z-index: 10;
    }
    /* TODO - @JZ make sections adjust for this div with padding-top */
    #sidebar_info{
        display: flex;
        position:absolute;
        right:0;
        justify-content: flex-end;
        flex-flow: column;
        /* padding-right:10px;
        padding-top:10px;
        padding-bottom: 10px; */
        padding: 10px;
        background-color: #f3f2ef;
        border-bottom: 1px solid #ccc;
        box-shadow: 0px 0px 12px #999;
    }
    #sidebar_greeting,#sidebar_email,#sidebar_accountID{
        display:flex;
        justify-content: flex-end;
    }
    .selected{
        background-color:rgb(54,114,176) !important;
        font-weight:bold !important;
    }
    .bold{
        font-weight:bold;
    }
    .menu_item{
        font-size: 16px;
        color: white;
    }
    .menu_item svg{
        width:20px;
    }
    .submenu_item{
        background-color: rgb(45,51,56) !important;
        /* background-color: red !important; */
        margin-right:5px;
        display:none;
    }
    .ul_products{
        width: 100%;
    }
    i {
        padding-right: 8px;
        font-size:14px;
    }
    #sidebar_options{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    #sidebar_options svg{
        padding-right:6px;
    }
    #sidebar_menu ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    #sidebar_menu li{
        display:flex;
        flex-wrap:wrap;
        align-items: center;
        /* height:40px; */
        width: 100%;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: rgb(31,35,39)
        
    }
    .submenu_padding_adjust{
        padding-bottom: 10px !important;
    }
    #sidebar_menu .li_products{
        padding-bottom:0;
    }
    .ul_products{
        margin-top:10px;
        display:flex;
    }
    /* #sidebar_menu li:nth-child(odd){
        background-color: rgb(81, 168, 255);
    }
    #sidebar_menu li:nth-child(even){
        background-color: rgb(16, 110, 218);
    } */
    #sidebar_menu a{
        color:#fff;
        /* text-shadow: 1px 1px 2px #777; */
        text-decoration: none;
    }
        
@media all and (min-width:700px){
    #sidebar_greeting,#sidebar_email,#sidebar_accountID{
        order:1
    }
    #sidebar_menu{
        max-width:var(--nav-sidebar-width);
        height:100%;
        position: fixed;
    }
    #sidebar_container{
        margin-left:180px;
    }
    #sidebar_avatar{
        height:32px;
        width:32px;
        background-color: rebeccapurple;
        order: 1;
        display: flex;
    }
}
</style>