<template>
    <div id="tos_wrapper">
        <div id="tos_container">
            <div id="tos">
              <h1 class="bold title main-title">Terms Of Service TradingTools Software LLC</h1>
              <div class="hr"></div>
              <div class="hr"></div>
              <div class="sub-title">Terms of Service ("Terms")</div>
              <div class="hr"></div>
              <div class="main-tos-content">
                <p>Our Terms of Service were last updated on <span class="italics">03/22/2024</span>.</p>
                <p>Please read these terms and conditions carefully before using Our Service.</p>
                <ol>
                    <li>
                        <h2>Interpretation and Definitions</h2>
                        <div class="li-content">
                            <h3>Interpretation</h3>
                            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                            <h3>Definitions</h3>
                            <p>
                                For the purposes of these Terms of Service:
                                <ul>
                                    <li><span class="bold">“Affiliate”</span> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                                    <li><span class="bold">“Account”</span> means a unique account created for You to access our Service or parts of our Service.</li>
                                    <li><span class="bold">“Company”</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to TradingTools.Software LLC.</li>
                                    <li><span class="bold">"Country"</span> refers to United States of America</li>
                                    <li><span class="bold">"Content"</span> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
                                    <li><span class="bold">"Device"</span> means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>
                                    <li><span class="bold">"Discord Server"</span> means the TradingView Input Optimizer Discord server, accessible from <a href="https://discord.gg/nfh8MzTt">https://discord.gg/nfh8MzTt</a></li>
                                    <li><span class="bold">"Terms of Service"</span> (also referred as "Terms") mean these Terms of Service that form the entire agreement between You and the Company regarding the use of the Service.</li>
                                    <li><span class="bold">"Third-party Social Media Service"</span> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                                    <li><span class="bold">"Website"</span> refers to TradingTools.Software, accessible from <a href="https://www.tradingtools.software">https://www.tradingtools.software</a></li>
                                    <li><span class="bold">"You"</span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."</li>
                                </ul>
                            </p>
                        </div>
                    </li>
                    <li>
                        <h2>Acknowledgment</h2>
                        <p>These are the Terms of Service governing the use of this Service and the agreement that operates between You and the Company. These Terms of Service set out the rights and obligations of all users regarding the use of the Service.</p>
                        <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms of Service. These Terms of Service apply to all visitors, users and others who access or use the Service.</p>
                        <p>By accessing or using the Service You agree to be bound by these Terms of Service. If You disagree with any part of these Terms of Service then You may not access the Service.</p>
                        <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                        <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                    </li>
                    <li>
                        <h2>User Accounts</h2>
                        <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
                        <p>When You sign-up on our Website, you agree that you will receive Newsletters from us. You can unsubscribe from them once you have received the first one.</p>
                        <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
                        <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
                        <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
                        <p>You have the right to close your Client Account at any time once all obligations associated with the Client Account have been completed.</p>
                        <p>You acknowledge and agree that if we disable access to your Client Account, you may be prevented from accessing the tools</p>
                    </li>
                    <li>
                        <h2>Disclaimer</h2>
                        <p><span class="bold">Important:</span> We are not a trading platform nor does it provide trading advice. We provide tools for financial markets inc cryptocurrencies. All trading you do during or after using our services is solely your responsibility and for your account and we nor our employees have any responsibility in your trading results.</p>
                        <p>We do not provide financial, investment, legal, tax or any other professional advice. We are not a broker, financial advisor, investment advisor, portfolio manager or tax advisor. You acknowledge and agree that we are not responsible for your use of any information that you obtain through the usage of our services. Your decisions made in reliance on the products or services or your interpretations of the data are your own for which you have full responsibility. You expressly agree that your use of the services is at your sole risk.</p>
                    </li>
                    <li>
                        <h2>Prices, Payment Terms and Refunds</h2>
                        <div class="li-content">
                            <ol class="letter-ol">
                                <li>All prices, discounts, and promotions posted on the Products or Services are subject to change without notice. The price charged for a product or service will be the price advertised on the Product, or Service at the time the order is placed, subject to the terms of any promotions or discounts, your geographical location or residence, chosen payment method. You will be charged the price advertised at the time the order is placed, in accordance with the chosen frequency of payment (monthly, quarterly, semi-annually, yearly etc.) until terminated as outlined under these Terms of Use.</li>
                                <li>If we increase our prices, that increase will only apply to purchases made after the date the increase comes into effect. The prices posted in the Product or Service may not include applicable discounts or taxes until the profile data in your Client Account is fully completed by you. We strive to display accurate price information, however, we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. We reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any orders arising from such occurrences.</li>
                                <li>Additional charges or taxes may be applied by your payment service provider, issuer bank or intermediary which are beyond our control.</li>
                                <li>You must initiate payment or unconditionally agree to advance debit for the charges related to the products or services when submitting the order. If you do not complete this payment to our satisfaction, we will cancel your order.</li>
                                <li>You can use any available and the most convenient payment method currently available on our website for all purchases. However, we do not guarantee the availability of any payment method at any moment. We may add, remove or suspend any payment method temporarily or permanently by our own discretion.</li>
                                <li>You represent and warrant that: 
                                    <ol class="letter-romanumeral-ol">
                                        <li>the payment information you supply to us and any payment service provider (“PSP”) we may use is true, correct and complete</li>
                                        <li>you are duly authorized to use such payment method for the purchase</li>
                                        <li>charges incurred by you will be honoured by the issuer of your payment method</li>
                                        <li>You will pay charges incurred by you at the posted prices, including all applicable taxes, if any, regardless of the amount quoted on the our website at the time of your order.</li>
                                    </ol>
                                </li>
                                <li>Unless otherwise required by law, we do not provide any refund or credit.</li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h2>Use of the tools</h2>
                        <p>Your use of our tools is subject to all applicable local, provincial and federal laws and regulations.</p>
                        <p>Unless you have been specifically permitted to do so in a separate agreement with us, you agree that you will not reproduce, duplicate, copy, sell, trade or resell the Contents (defined below) or any of our products and services for any purpose.</p>
                        <p>
                            You agree that you will not use our Website and community spaces to:
                            <ul>
                                <li>access content and data that is not intended for you;</li>
                                <li>restrict disrupt or disable service to users, hosts, servers or networks or otherwise attempt to interfere with the proper working of the tools;</li>
                                <li>promote or engage in any unlawful or illegal activities or internet abuse, including unsolicited advertising and spam;</li>
                                <li>promote, upload, post, or otherwise make available any material that is abusive, harassing, obscene, vulgar, hateful, sexually explicit, invasive of another’s privacy, defamatory or otherwise objectionable or illegal, or that discriminates based on race, gender, religion, nationality, disability, sexual orientation, age, family status or any other legally protected class;</li>
                                <li>upload, post or otherwise make available any material that contains viruses, worms, malware or other malicious software;</li>
                                <li>upload, post, or otherwise make available any material that you do not have a right to make available or that infringes any patent, trade-mark, trade secret, copyright or other proprietary rights of any person. You shall be solely liable for any damage resulting from any infringement of the intellectual property of any third-party;</li>
                                <li>engage in any activity that interferes with or disrupts the use of the Services provided, including but not limited to hacking or bypassing any measures we may use to prevent unauthorized access to the Services or Community Spaces;</li>
                                <li>track, trace or harvest any information on the Services provided; or</li>
                                <li>sell, distribute, copy, sub-license, loan, transfer, duplicate, reproduce, trade, resell, or otherwise monetize the services provided without our prior consent.</li>
                            </ul>
                        </p>
                        <p>You agree that you are solely responsible for (and that we have no responsibility to you or to any third party for) any breach of your obligations under these Terms of Use and for the consequences (including any loss or damage which we may suffer) of any such breach.</p>
                    </li>
                    <li>
                        <h2>Links to third party platforms</h2>
                        <p>Use of certain links on our website will direct you to third party (including but not limited to Enterprise Partners) feeds, software, websites or mobile applications (collectively, “Third Party Platforms”). Such Third Party Platforms are not under our control, and we are not responsible for the contents of any such Third Party Platforms or any link contained in such Third Party Platform. Links to Third Party Platforms included on the Software are provided for your convenience, and the inclusion of such links does not imply a recommendation or endorsement by us of any such Third Party Platform or the products or services or information offered therein. If you decide to access any Third Party Platform information linked to the Software, you do so entirely at your own risk. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by, or in connection with the use of or reliance on any such third party Platform or information.</p>
                    </li>
                    <li>
                        <h2>Ownership of intellectual property rights</h2>
                        <p>The contents of the tools include, without limitation, all information, materials, design elements, text, images, photographs, illustrations, audio and video contents, artwork and graphics contained therein or otherwise made available to you in connection therewith (collectively the “Contents”).</p>
                        <p>The tools together with all trade-marks and other intellectual property displayed, distributed, or otherwise made available via our website, is our exclusive property. You may not assign or transfer any of the Contents and you may not grant a license to use or access the tools or contents of the Website to any party without our written consent.</p>
                        <p>Any unauthorized or prohibited use of any Contents may subject you to civil liability, criminal prosecution, or both, under applicable federal, provincial, state and local laws.</p>
                        <p>The limited rights granted to you under these Terms of Use may be revoked by us at any time for any reason whatsoever. </p>
                    </li>
                    <li>
                        <h2>Limitation of liability</h2>
                        <p>To the maximum extent permitted by applicable law you expressly understand and agree that we and our respective directors, officers, employees or agents shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you in connection with your use of the services, however caused and under any theory of liability including, but not limited to, any loss of profit, lost opportunities, loss of data suffered, or other intangible loss.
Our maximum aggregate liability to you shall be limited to $100.00.
The limitations on our liability to you in this section shall apply whether or not we have been advised of or should have been aware of the possibility of any such losses arising.</p>
                    </li>
                </ol>
              </div>
            </div>
        </div>
    </div> 
  </template>
  
  <script>
  </script>

  <style scoped>
    *{
        font-size:14px;
    }
    h1{
        font-size: 24px;
    }
    h2{
        font-size: 18px;
    }
    h3{
        font-size:16px;
    }
    .title{
        text-align:center;
    }
    .main-title{
        padding-bottom:5px;
    }
    .hr{
        width:100%;
        height:1px;
        margin:1px;
        background-color:black;
    }
    .sub-title{
        padding-top:10px;
        padding-bottom:2px;
        width:100%;
        text-align:left;
    }
    .li-content{
        padding-top:10px;
        padding-bottom:10px;
        h3{
            padding-top:5px;
            padding-bottom:5px;
        }
    }
    p{
        padding-top:5px;
        padding-bottom:5px;
    }
    ol{
        padding:10px;
        padding-left:20px;
        list-style-type:decimal;
        text-align:left;
        li{
            padding-top:5px;
            padding-bottom:5px;
            padding-left:5px;
        }
    }
    .letter-ol{
        padding:10px;
        padding-left:20px;
        list-style-type:lower-alpha;
    }
    .letter-romanumeral-ol{
        padding:10px;
        padding-left:20px;
        list-style-type:lower-roman;
    }
    ul{
        padding:10px;
        padding-left:20px;
        list-style-type:circle;
        text-align:left;
        li{
            padding-top:5px;
            padding-bottom:5px;
            padding-left:5px;
        }
    }
    #tos{
        display:flex;
        flex-wrap: wrap;
        padding-top:30px;
        padding-bottom:30px;
        justify-content: center;
        margin:0px 20px;
        text-align:left;
    }
    
    #tos_container{
        max-width: 700px;
        /* height: 80%; */
        margin: 20px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content:space-around;
        background-color: #eff2f6;
        box-shadow: 0px 0px 12px #999;
        /* background-color: #96c2ed; */
        /* background-color: #69a8e7;
        box-shadow: 0px 0px 10px #96c2ed;
        color:#eee;
        text-shadow: 1px 1px 2px #777; */
    }
    
    #tos_wrapper{
        background-color: #cecee0;
        /* background-color: dodgerblue; */
        /* background-color: #f3f2ef; */
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height:100%;
        justify-content: center;
        text-align: center;
        align-items: flex-start;
    }
    .main-tos-content{
        padding-top:10px;
    }
    .bold{
        font-weight:bold;
    }
    .italics{
        font-style:italic;
    }
    /* Tablet menu */
    @media all and (min-width: 700px){
    
    }
  </style>