<template>
    <LicenseKeys></LicenseKeys>
    <router-view />
</template>

<script>
    import LicenseKeys from '@/components/licensekeys.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-license-keys',
        components: {
            LicenseKeys
        },
        mounted(){
            this.setSidebarHidden(false)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>