import { createStore } from 'vuex'

export default createStore({
    
    state: {
        // notifyRenewal: false,
        sessionReady: false,
        sidebarHidden: false,
        route: {},
        loggedIn: false,
        loggedInEmail: '?',
        accountID: '??',
        loggedInFirstName: '???',
        licenses: [],
        // licenses:[
        //     {
        //         "id": 1,
        //         "name": "jaewulf@gmail.com",
        //         "key": "7PPVD-YYDLA-LHLPV-YDD4A",
        //         "product": "TradingView Input Optimizer",
        //         "product_code": "1",
        //         "purchase_date": "2022-12-04T13:40:15.000Z",
        //         "stripe_productID": "prod_MRRuOkh4kYQHOg",
        //         "active": false,
        //         "createdAt": "2022-12-04T13:40:15.000Z",
        //         "updatedAt": "2022-12-04T13:40:15.000Z",
        //         "user_id": 1,
        //         "subscriptions": [
        //             {
        //                 "id": 16,
        //                 "stripe_subscriptionID": "fake_stripe_subscriptionID",
        //                 "stripe_productID": "prod_MRRuOkh4kYQHOg",
        //                 "current_period_end": "2022-07-16T12:00:00.000Z",
        //                 "current_period_start": "2022-12-16T12:00:00.000Z",
        //                 "current_period_end_timestamp": 1670097600,
        //                 "current_period_start_timestamp": 1671220800,
        //                 "retry_count": 0,
        //                 "createdAt": "2022-12-05T00:36:56.000Z",
        //                 "updatedAt": "2022-12-05T00:36:56.000Z",
        //                 "user_id": 1,
        //                 "license_id": 1
        //             }
        //         ]
        //     },
        //     {
        //         "id": 2,
        //         "name": "jaewulf@gmail.com",
        //         "key": "123456$",
        //         "product": "Autobot Assembler",
        //         "product_code": "2",
        //         "purchase_date": "2022-12-01T16:26:53.000Z",
        //         "stripe_productID": "garbage_product_id",
        //         "active": true,
        //         "createdAt": "2022-12-05T00:26:53.000Z",
        //         "updatedAt": "2022-12-05T00:26:53.000Z",
        //         "user_id": 1,
        //         "subscriptions": [
        //             {
        //                 "id": 17,
        //                 "stripe_subscriptionID": "garbage_day",
        //                 "stripe_productID": "garbage_product_id",
        //                 "current_period_end": "2022-11-01T12:00:00.000Z",
        //                 "current_period_start": "2022-12-30T12:00:00.000Z",
        //                 "current_period_end_timestamp": 1667329200,
        //                 "current_period_start_timestamp": 1672430400,
        //                 "retry_count": 0,
        //                 "createdAt": "2022-12-05T00:42:57.000Z",
        //                 "updatedAt": "2022-12-05T00:42:57.000Z",
        //                 "user_id": 1,
        //                 "license_id": 2
        //             }
        //         ]
        //     }
        // ]
    },
    getters: {
        // getLicenses(state){
        //     return state.licenses;
        // }
    },
    mutations: {
        setSessionReady(state, value){
            console.log(`setSessionReady=${value}`);
            state.sessionReady = value;
        },
        setSidebarHidden(state, value){
            console.log(`setSideBarhidden=${value}`);
            state.sidebarHidden = value;
        },
        SET_ROUTE(state, route) {
            console.log(`setting route to:${route}`);
            state.route = route;
        },
        setLoggedIn(state, value){
            console.log(`setLoggedIn=${value}`);
            state.loggedIn = value;
        },
        setLoggedInEmail(state, value){
            console.log(`setLoggedInEmail=${value}`);
            state.loggedInEmail = value;
        },
        setAccountID(state, value){
            console.log(`setAccountID=${value}`);
            state.accountID = value;
        },
        setLoggedInFirstName(state, value){
            console.log(`setLoggedInFirstName=${value}`);
            state.loggedInFirstName = value;
        },
        setLicenses(state, value){
            console.log(`setLicenses={value below}`);
            console.log(value);
            state.licenses = value;
        },
        // setNotifyRenewal(state, value){
        //     console.log(`notifyRenewal=${value}`);
        //     state.notifyRenewal = value;
        // }
    },
    actions: {
        getSession: async function(context) {
            return new Promise((resolve,reject) => {
                try{
                    (async() =>{
                        console.log("Getting session info if available...");
                        const url = `https://${document.location.hostname}/api/login/info`;

                        // const cookie = getCookie('sid');
                        // console.log('cookie below');
                        // console.log(cookie);

                        const options = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            credentials: 'include'
                        };
                        //todo get the json response for setnotifyrenewal
                        try{
                            var response = await fetch(url,options);
                            const json_response = await response.json();
                            console.log("Showing json response below");
                            console.log(json_response);
                            console.log(`Setting sessionReady...`);
                            context.commit('setSessionReady', true);

                            if(response.ok){
                                console.log("Got login session info successfully!");
                                context.commit('setLoggedIn', true);
                                context.commit('setLoggedInEmail',json_response.email);
                                context.commit('setAccountID',json_response.account_id);
                                context.commit('setLoggedInFirstName',json_response.first_name);
                                context.commit('setLicenses',json_response.licenses);
                                resolve("Session Found");
                            }else{
                                console.log("Error getting session details.  Not logged in?");
                                context.commit('setLoggedIn', false);

                                // context.commit('setLicenses',
                                //     [
                                //         {
                                //             "id": 1,
                                //             "name": "jaewulf@gmail.com",
                                //             "key": "7PPVD-YYDLA-LHLPV-YDD4A",
                                //             "product": "TradingView Input Optimizer",
                                //             "product_code": "1",
                                //             "purchase_date": "2022-12-11T04:55:22.000Z",
                                //             "stripe_productID": "prod_MRRuOkh4kYQHOg",
                                //             "active": true,
                                //             "createdAt": "2022-12-11T04:55:22.000Z",
                                //             "updatedAt": "2022-12-11T04:55:22.000Z",
                                //             "user_id": 1,
                                //             "licenseSessions": [
                                //                 {
                                //                     "id": 4,
                                //                     "uid": "abc2",
                                //                     "active": true,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:51:17.000Z",
                                //                     "updatedAt": "2022-12-12T04:51:17.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 5,
                                //                     "uid": "abc3",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:51:39.000Z",
                                //                     "updatedAt": "2022-12-12T04:51:39.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 6,
                                //                     "uid": "abc4",
                                //                     "active": true,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:51:42.000Z",
                                //                     "updatedAt": "2022-12-12T04:51:42.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 7,
                                //                     "uid": "abc5",
                                //                     "active": true,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:51:50.000Z",
                                //                     "updatedAt": "2022-12-12T04:51:50.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 8,
                                //                     "uid": "abc6",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:52:01.000Z",
                                //                     "updatedAt": "2022-12-12T04:52:01.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 9,
                                //                     "uid": "abc7",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:52:20.000Z",
                                //                     "updatedAt": "2022-12-12T04:52:20.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 10,
                                //                     "uid": "abc8",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:52:23.000Z",
                                //                     "updatedAt": "2022-12-12T04:52:23.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 11,
                                //                     "uid": "abc9",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:53:05.000Z",
                                //                     "updatedAt": "2022-12-12T04:53:05.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 12,
                                //                     "uid": "abc10",
                                //                     "active": true,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:56:52.000Z",
                                //                     "updatedAt": "2022-12-12T04:56:52.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 13,
                                //                     "uid": "abc11",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:57:03.000Z",
                                //                     "updatedAt": "2022-12-12T04:57:03.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 14,
                                //                     "uid": "abc12",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:57:16.000Z",
                                //                     "updatedAt": "2022-12-12T04:57:16.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 15,
                                //                     "uid": "abc13",
                                //                     "active": true,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:57:32.000Z",
                                //                     "updatedAt": "2022-12-12T04:57:32.000Z",
                                //                     "license_id": 1
                                //                 },
                                //                 {
                                //                     "id": 16,
                                //                     "uid": "abc14",
                                //                     "active": false,
                                //                     "info": null,
                                //                     "createdAt": "2022-12-12T04:57:39.000Z",
                                //                     "updatedAt": "2022-12-12T04:57:39.000Z",
                                //                     "license_id": 1
                                //                 }
                                //             ],
                                //             "subscriptions": [
                                //                 {
                                //                     "id": 1,
                                //                     // "stripe_subscriptionID": "sub_1MDhZMCdWcVFfynAVD6sgfGk",
                                //                     "stripe_subscriptionID": null,
                                //                     "stripe_productID": "prod_MRRuOkh4kYQHOg",
                                //                     "current_period_end": "2022-12-14T04:55:20.000Z",
                                //                     "current_period_start": "2022-12-11T04:55:20.000Z",
                                //                     "current_period_end_timestamp": 1670993720,
                                //                     "current_period_start_timestamp": 1670734520,
                                //                     "retry_count": 0,
                                //                     "createdAt": "2022-12-11T04:55:22.000Z",
                                //                     "updatedAt": "2022-12-11T04:55:22.000Z",
                                //                     "user_id": 1,
                                //                     "license_id": 1
                                //                 }
                                //             ]
                                //         }
                                //     ],
                                // );

                                reject('No Session Detected');
                            }
                        }catch(error){
                            // this.conf_err = true;
                            // this.conf_err_msg = "Too many attempts.  Please try again later.";
                            console.error(error);
                            reject(error);
                        }
                    })()
                }catch(error){
                    reject(error);
                }
            });
        }
    },
    modules: {}
})