<template>
    <Signup></Signup>
    <router-view />
</template>

<script>
    import Signup from '@/components/signup.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'vue-sign-up',
        components: {
            Signup
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        }
    }
</script>

<style>
</style>